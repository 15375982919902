import { setTimeout } from 'core-js/web/timers'
import React, { Component } from 'react'

// Page Loading Components
import * as All from 'react-loader-spinner'

export class LoaderScreen extends Component {
	props = {}

	debug = false

	timeoutHandler = null

	resetTimeoutInterval = 30

	isMounted = false

	loader = {
		type: 'ThreeDots',
		color: '#b4d93c',
		backgroundColor: '#fff',
		height: 80,
		width: 80,
		arialLabel: '',
		wrapperClass: '',
		visible: true,
	}

	state = {
		timeout: 3000,
		isLoading: true,
	}

	constructor(props) {
		super(props)
		this.elementRef = React.createRef()
		this.props = props

		this.setProperties(this.props.config)
	}

	setProperties(config, executeFunc = false) {
		// If config is not object stop the process
		if ('object' !== typeof config) return

		// Validate and set LoaderScreen visibility
		if ('boolean' === typeof config.debug) this.debug = config.debug

		// Validate and set LoaderScreen visibility
		if ('number' === typeof config.resetTimeoutInterval)
			this.resetTimeoutInterval = config.resetTimeoutInterval

		// Validate and set LoaderScreen visibility
		if ('number' === typeof config.timeout)
			this.state.timeout = config.timeout

		// Validate and set LoaderScreen visibility
		if ('boolean' === typeof config.isLoading)
			this.state.isLoading = config.isLoading

		/**
		 * React Loader Spinner Config
		 */
		if ('object' === typeof config.loader) {
			if ('boolean' === typeof config.loader.visible)
				this.loader.visible = config.loader.visible // visible: boolean

			if ('string' === typeof config.loader.type)
				this.loader.type = config.loader.type // wrapperStyle: string

			if ('string' === typeof config.loader.wrapperClass)
				this.loader.wrapperClass = config.loader.wrapperClass // wrapperClass: string

			if ('string' === typeof config.loader.color)
				this.loader.color = config.loader.color // color: string

			if ('string' === typeof config.loader.backgroundColor)
				this.loader.backgroundColor = config.loader.backgroundColor // Not part of the npm package

			if (
				'string' === typeof config.loader.height ||
				'number' === typeof config.loader.height
			)
				this.loader.height = config.loader.height // height: string | number

			if (
				'string' === typeof config.loader.width ||
				'number' === typeof config.loader.width
			)
				this.loader.width = config.loader.width // width: string | number

			if ('string' === typeof config.loader.arialLabel)
				this.loader.arialLabel = config.loader.arialLabel // arialLabel: string
		}
	}

	componentDidMount() {
		this.isMounted = true
	}

	componentWillUnmount() {
		this.isMounted = false

		this.setState({ isLoading: false })
	}

	componentDidUpdate() {
		const { isLoading } = this.props.config

		// If debug is enabled
		if (this.debug) {
			console.log(this.props.config)
		}

		this.loadingTimeout(() => {
			this.setState({ isLoading: false })
		}, this.state.timeout)
	}

	componentHasRef() {
		return this.elementRef.current != null
	}

	loadingTimeout = (callback, timeout = 1000) => {
		// Make sure a valid callback was provided
		if (!callback || typeof callback !== 'function') return

		if ('' === callback) return

		// Set a timeout to run after event ends
		this.timeoutHandler = setTimeout(callback, timeout)

		// If debug is enabled
		if (this.debug) {
			console.log(`Timeout Interval: ${this.timeoutHandler}`)
		}

		if (this.resetTimeoutInterval <= this.timeoutHandler) {
			clearTimeout(this.timeoutHandler)
		}
	}

	loaderTypes = () => {
		switch (this.loader.type) {
			case 'Audio':
				return (
					<All.Audio
						type={this.loader.type}
						wrapperClass={`loader ${this.loader.wrapperClass}`}
						color={this.loader.color}
						height={this.loader.height}
						width={this.loader.width}
						arialLabel={this.loader.arialLabel}
						visible={this.loader.visible}
					/>
				)
			case 'BallTriangle':
				return (
					<All.BallTriangle
						type={this.loader.type}
						wrapperClass={`loader ${this.loader.wrapperClass}`}
						color={this.loader.color}
						height={this.loader.height}
						width={this.loader.width}
						arialLabel={this.loader.arialLabel}
						visible={this.loader.visible}
					/>
				)
			case 'Bars':
				return (
					<All.Bars
						type={this.loader.type}
						wrapperClass={`loader ${this.loader.wrapperClass}`}
						color={this.loader.color}
						height={this.loader.height}
						width={this.loader.width}
						arialLabel={this.loader.arialLabel}
						visible={this.loader.visible}
					/>
				)
			case 'Circles':
				return (
					<All.Circles
						type={this.loader.type}
						wrapperClass={`loader ${this.loader.wrapperClass}`}
						color={this.loader.color}
						height={this.loader.height}
						width={this.loader.width}
						arialLabel={this.loader.arialLabel}
						visible={this.loader.visible}
					/>
				)
			case 'CradleLoader':
				return (
					<All.CradleLoader
						type={this.loader.type}
						wrapperClass={`loader ${this.loader.wrapperClass}`}
						color={this.loader.color}
						height={this.loader.height}
						width={this.loader.width}
						arialLabel={this.loader.arialLabel}
						visible={this.loader.visible}
					/>
				)
			case 'Grid':
				return (
					<All.Grid
						type={this.loader.type}
						wrapperClass={`loader ${this.loader.wrapperClass}`}
						color={this.loader.color}
						height={this.loader.height}
						width={this.loader.width}
						arialLabel={this.loader.arialLabel}
						visible={this.loader.visible}
					/>
				)
			case 'Hearts':
				return (
					<All.Hearts
						type={this.loader.type}
						wrapperClass={`loader ${this.loader.wrapperClass}`}
						color={this.loader.color}
						height={this.loader.height}
						width={this.loader.width}
						arialLabel={this.loader.arialLabel}
						visible={this.loader.visible}
					/>
				)
			case 'MutatingDots':
				return (
					<All.MutatingDots
						type={this.loader.type}
						wrapperClass={`loader ${this.loader.wrapperClass}`}
						color={this.loader.color}
						height={this.loader.height}
						width={this.loader.width}
						arialLabel={this.loader.arialLabel}
						visible={this.loader.visible}
					/>
				)
			case 'Oval':
				return (
					<All.Oval
						type={this.loader.type}
						wrapperClass={`loader ${this.loader.wrapperClass}`}
						color={this.loader.color}
						height={this.loader.height}
						width={this.loader.width}
						arialLabel={this.loader.arialLabel}
						visible={this.loader.visible}
					/>
				)
			case 'Plane':
				return (
					<All.Plane
						type={this.loader.type}
						wrapperClass={`loader ${this.loader.wrapperClass}`}
						color={this.loader.color}
						height={this.loader.height}
						width={this.loader.width}
						arialLabel={this.loader.arialLabel}
						visible={this.loader.visible}
					/>
				)
			case 'Puff':
				return (
					<All.Puff
						type={this.loader.type}
						wrapperClass={`loader ${this.loader.wrapperClass}`}
						color={this.loader.color}
						height={this.loader.height}
						width={this.loader.width}
						arialLabel={this.loader.arialLabel}
						visible={this.loader.visible}
					/>
				)
			case 'RevolvingDot':
				return (
					<All.RevolvingDot
						type={this.loader.type}
						wrapperClass={`loader ${this.loader.wrapperClass}`}
						color={this.loader.color}
						height={this.loader.height}
						width={this.loader.width}
						arialLabel={this.loader.arialLabel}
						visible={this.loader.visible}
					/>
				)
			case 'Rings':
				return (
					<All.Rings
						type={this.loader.type}
						wrapperClass={`loader ${this.loader.wrapperClass}`}
						color={this.loader.color}
						height={this.loader.height}
						width={this.loader.width}
						arialLabel={this.loader.arialLabel}
						visible={this.loader.visible}
					/>
				)
			case 'TailSpin':
				return (
					<All.TailSpin
						type={this.loader.type}
						wrapperClass={`loader ${this.loader.wrapperClass}`}
						color={this.loader.color}
						height={this.loader.height}
						width={this.loader.width}
						arialLabel={this.loader.arialLabel}
						visible={this.loader.visible}
					/>
				)
			case 'ThreeDots':
				return (
					<All.ThreeDots
						type={this.loader.type}
						wrapperClass={`loader ${this.loader.wrapperClass}`}
						color={this.loader.color}
						height={this.loader.height}
						width={this.loader.width}
						arialLabel={this.loader.arialLabel}
						visible={this.loader.visible}
					/>
				)
			case 'Triangle':
				return (
					<All.Triangle
						type={this.loader.type}
						wrapperClass={`loader ${this.loader.wrapperClass}`}
						color={this.loader.color}
						height={this.loader.height}
						width={this.loader.width}
						arialLabel={this.loader.arialLabel}
						visible={this.loader.visible}
					/>
				)
			case 'Watch':
				return (
					<All.Watch
						type={this.loader.type}
						wrapperClass={`loader ${this.loader.wrapperClass}`}
						color={this.loader.color}
						height={this.loader.height}
						width={this.loader.width}
						arialLabel={this.loader.arialLabel}
						visible={this.loader.visible}
					/>
				)
		}
	}

	render() {
		const { isLoading, timeout } = this.state

		let elemStyle = {}
		let bgColor = this.loader.backgroundColor
		let getSeconds = (timeout / 1000 / 60 / 60) * 60 * 60
		// getSeconds = getSeconds > 2 ? getSeconds - 2 : getSeconds

		if (bgColor) {
			elemStyle = {
				backgroundColor: bgColor,
				animation: `fadeOut ${getSeconds}s linear forwards`,
				WebkitAnimation: `fadeOut ${getSeconds}s linear forwards`,
				MozAnimation: `fadeOut ${getSeconds}s linear forwards`,
				OAnimation: `fadeOut ${getSeconds}s linear forwards`,
				msAnimation: `fadeOut ${getSeconds}s linear forwards`,
				// animation: `fadeOut 2.5s ease`,
				// WebkitAnimation: `fadeOut 2.5s ease`,
				// MozAnimation: `fadeOut 2.5s ease`,
				// OAnimation: `fadeOut 2.5s ease`,
				// msAnimation: `fadeOut 2.5s ease`,

				// animationDelay: `${getSeconds}s`,
				// WebkitAnimationDelay: `${getSeconds}s`,
				// MozAnimationDelay: `${getSeconds}s`,
				// OAnimationDelay: `${getSeconds}s`,
				// msAnimationDelay: `${getSeconds}s`,
			}
		}

		if (isLoading) {
			return (
				<div
					className="loader_screen"
					ref={this.elementRef}
					style={elemStyle}
				>
					{this.loaderTypes()}
				</div>
			)
		} else {
			return <></>
		}
	}
}

export default LoaderScreen
