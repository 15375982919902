import React, { Component } from 'react'
import Slider from 'react-slick'

import helpers from '../../../helpers/Helpers'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// Read https://react-slick.neostack.com/docs/example/custom-slides

export class SlickCarousel extends Component {
	displaySlider = true
	slidesMarkupValid = true
	hasCustomSlideMarkup = false

	config = {
		className: '',
		dots: true,
		infinite: true,
		centerMode: false,
		focusOnSelect: true,

		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		slideContentPosition: 'positioned__center',

		autoplay: true,
		autoplaySpeed: 5000,
		pauseOnHover: true,
		cssEase: 'ease-in-out',
	}

	constructor(props) {
		super(props)

		this.setProperties(props.config)

		let hasCustomSlides = helpers.getPropExists(
			this.config,
			'slidesMarkup',
			''
		)

		if ('function' === typeof hasCustomSlides) {
			this.hasCustomSlideMarkup = true
		}
	}

	setProperties(config) {
		if ('object' === typeof config) {
			if (config.className) {
				config.className = `gp-carousel ${config.className}`
			} else {
				config.className = `gp-carousel`
			}

			this.config = helpers.arrayMerge(this.config, config)

			if (helpers.checkProp(config, 'slideContentPosition')) {
				switch (config.slideContentPosition) {
					case 'left':
						this.config.slideContentPosition = 'positioned__left'
						break
					case 'right':
						this.config.slideContentPosition = 'positioned__right'
						break
					default:
					case 'center':
						this.config.slideContentPosition = 'positioned__center'
						break
				}
			} else {
				this.config.slideContentPosition = 'positioned__center'
			}
		} else {
			this.execErrorMessage(
				`The "setProperties" method requires first parameter to be an "object".`
			)
		}
	}

	// Execute new Error Message
	execErrorMessage(message) {
		if ('string' !== typeof message) {
			throw new Error(message)
		}
	}

	defaultSlidesMarkup() {
		let slides = helpers.getPropExists(this.config, 'items', [])
		let slideClass = `inner-slide-wrapper ${this.config.slideContentPosition}`

		const _validate = {
			title: (_title) => {
				if ('string' === typeof _title) {
					return <h4 className="title">{_title}</h4>
				}
			},
			subTitle: (_subTitle) => {
				if ('string' === typeof _subTitle) {
					return <h5 className="sub-title">{_subTitle}</h5>
				}
			},
			image: (_image) => {
				if ('string' === typeof _image) {
					return (
						<div className="image">
							<img src={_image} />
						</div>
					)
				}
			},
			desc: (_desc) => {
				if ('string' === typeof _desc) {
					return <div className="desc">{_desc}</div>
				}
			},
		}

		return slides.map(({ title, subTitle, image, description }, index) => (
			<div key={index} className={slideClass}>
				{_validate.image(image)}
				{_validate.title(title)}
				{_validate.subTitle(subTitle)}
				{_validate.desc(description)}
			</div>
		))
	}

	slidesMarkup() {
		if (this.hasCustomSlideMarkup) {
			if ('function' !== typeof this.config.slidesMarkup) {
				this.slidesMarkupValid = false
			}

			return this.config.slidesMarkup()
		} else {
			return this.defaultSlidesMarkup()
		}
	}

	// Checks the validity of slider configurations and markups
	execValidations() {
		if (!this.slidesMarkupValid) {
			this.displaySlider = false
			this.execErrorMessage(
				`The "slidesMarkup" property only accepts "Function" as value.`
			)
		}

		return this.displaySlider
	}

	render() {
		if (this.execValidations()) {
			return <Slider {...this.config}>{this.slidesMarkup()}</Slider>
		} else {
			return <></>
		}
	}
}

export default SlickCarousel
