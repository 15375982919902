import React, { useEffect } from "react"

function Lightbox(props) {
	return (
		<>
		
		</>
	)
}

export default Lightbox