import React, { Component } from 'react'

import { Link } from 'react-router-dom'

import { v4 as uuidv4 } from 'uuid'

export class CircularCarouselComponent extends Component {
	// uniqueID = `circle-carousel-${Math.floor(Math.random() * 100000)}`
	uniqueID = `circle-carousel-${uuidv4()}`

	extraClasses = ''

	timeout = {
		handler: null,
		interval: 1000,
		resetInterval: 30,
	}

	controlsConfig = {
		navClass: '',
	}

	activeConfig = {
		title: true,
		icon: true,
		iconProxy: false,
		link: true,
		backgroundColor: true,
		backgroundImage: true,
	}

	itemConfig = {
		title: true,
		icon: true,
		link: true,
		backgroundColor: true,
		backgroundImage: true,
	}

	isMounted = false

	breakpoints = {}

	state = {
		// Private States
		activeItem: 0,
		prevItem: 0,
		nextItem: 0,
		lastItem: 0,
		rotationInterval: 0, // The value returned by setInterval()
		stopRotation: false,
		currentBreakpoint: 0,
		animate: false,

		// Prop States
		carouselClassnames: '', //360,
		carouselSize: 560, //360,
		carouselDeg: 0, //360,
		carouselWrapperSize: 760,
		itemSize: 120,
		itemDeg: 0, //360,
		activeContentSize: 2, // Accepts number as divisor to divide with {carouselSize} which is the dividend to get the size of the active content.
		debug: true,
		clone: false,
		autoRotate: false, // 'prev' or 'next' or if true rotate to next. Otherwise, autoRotate set to off
		timeInterval: 500,
		items: [],
	}

	constructor(props) {
		super(props)
		this.elementRef = React.createRef()
		this.props = props

		// Pass the {props.config} which contains the assigned carousel configurations
		this.setProperties(this.props.config, true)

		// console.table({
		// 	prevItem: this.state.prevItem,
		// 	activeItem: this.state.activeItem,
		// 	nextItem: this.state.nextItem,
		// })
	}

	setProperties(config, executeFunc = false) {
		// If config is not object stop the process
		if ('object' !== typeof config) return

		let pi = Math.PI
		let adjustment = null

		// Validate and set Reset Timeout Interval
		if ('object' === typeof config.timeout) {
			if ('number' === typeof config.timeout.interval) {
				this.timeout.interval = config.timeout.interval
			}
			if ('number' === typeof config.timeout.resetInterval) {
				this.timeout.resetInterval = config.timeout.resetInterval
			}
		}

		// Validate and set Carousel Size
		if ('string' === typeof config.carouselClassnames)
			this.state.carouselClassnames = config.carouselClassnames

		// Validate and set Carousel Size
		if (
			'number' === typeof config.carouselSize ||
			'string' === typeof config.carouselSize
		)
			this.state.carouselSize = parseInt(config.carouselSize)

		// Validate and set Carousel Degrees
		if (
			'number' === typeof config.carouselDeg ||
			'string' === typeof config.carouselDeg
		)
			this.state.carouselDeg = parseInt(config.carouselDeg)

		// Validate and set Carousel Wrapper Size
		if (
			'number' === typeof config.carouselWrapperSize ||
			'string' === typeof config.carouselWrapperSize
		)
			this.state.carouselWrapperSize = parseInt(
				config.carouselWrapperSize
			)

		// Validate and set Carousel Items Size
		if (
			'number' === typeof config.itemSize ||
			'string' === typeof config.itemSize
		)
			this.state.itemSize = parseInt(config.itemSize)

		// Validate and set Carousel Item Degrees
		if (
			'number' === typeof config.itemDeg ||
			'string' === typeof config.itemDeg
		)
			this.state.itemDeg = parseInt(config.itemDeg)

		// Validate and set the Active Content Size
		if ('number' === typeof config.activeContentSize) {
			this.state.activeContentSize = parseInt(config.activeContentSize)
		} else if (
			'string' === typeof config.activeContentSize &&
			'default' === config.activeContentSize
		) {
			this.state.activeContentSize = config.activeContentSize
		} else {
			this.state.activeContentSize = 'default'
		}

		// Validate and set Debug Mode
		if ('boolean' === typeof config.debug) this.state.debug = config.debug

		// Validate and set Carousel Item Cloning
		if ('boolean' === typeof config.clone) this.state.clone = config.clone

		// Validate and set Carousel Auto Rotation
		if (
			'boolean' === typeof config.autoRotate ||
			'string' === typeof config.autoRotate
		)
			this.state.autoRotate = config.autoRotate

		// Validate and set Carousel Auto Rotation Interval
		if (
			'number' === typeof config.timeInterval ||
			'string' === typeof config.timeInterval
		)
			this.state.timeInterval = parseInt(config.timeInterval)

		// Validate and set Carousel Items
		if (Array.isArray(config.items)) {
			if (config.items.length !== 0) {
				this.state.items = config.items
			}

			if (this.state.clone) {
				this.state.items = this.state.items.concat(this.state.items)
			}
		}

		// Merge the provided itemConfig with default
		if ('object' === typeof config.itemConfig)
			this.itemConfig = Object.assign(
				{},
				this.itemConfig,
				config.itemConfig
			)

		// Merge the provided activeConfig with default
		if ('object' === typeof config.activeConfig)
			this.activeConfig = Object.assign(
				{},
				this.activeConfig,
				config.activeConfig
			)

		// Merge the provided controlsConfig with default
		if ('object' === typeof config.controlsConfig)
			this.controlsConfig = Object.assign(
				{},
				this.controlsConfig,
				config.controlsConfig
			)

		// Responsive Breakpoints
		if (Array.isArray(config.breakpoints)) {
			// Create a mutable copy of {breakpoints} object property
			let breakpoints = config.breakpoints

			// Count total items of {breakpoints} object
			let responsiveItems = breakpoints.length

			// Create a mutable copy of {this.props.config}
			let baseConfig = this.props.config

			// Set {baseConfig} breakpoint to {9999}
			baseConfig['breakpoint'] = 9999

			// Remove the breakpoints property in {baseConfig} object
			// delete baseConfig.breakpoints

			// Add {baseConfig} in {breakpoints} object
			breakpoints[responsiveItems] = baseConfig

			// Set {this.breakpoints} value to {breakpoints} mutable copy
			this.breakpoints = breakpoints

			// Execute function calls here
			if (executeFunc) {
			}
		}

		// Setup carousel state
		this.state.lastItem = this.state.items.length

		if (this.state.activeItem === 0) {
			this.state.prevItem = this.state.lastItem - 1
			this.state.nextItem = this.state.activeItem + 1
		} else {
			this.state.prevItem = this.state.activeItem - 1
			this.state.nextItem = this.state.activeItem + 1

			// Adjust the position of the active carousel item
			adjustment =
				(this.state.carouselSize / this.state.lastItem / pi) *
				this.state.activeItem *
				2
			this.state.carouselDeg = adjustment
			this.state.itemDeg = adjustment
		}
	}

	componentDidMount() {
		this.isMounted = true

		// Execute autoRotation
		this.autoRotation(true)

		// Apply the assigned carousel size base on device width
		this.execResponsive()

		// Resize the carousel on component on mount
		this.execOnResize()
	}

	componentWillUnmount() {
		this.isMounted = false
	}

	componentDidUpdate() {
		const { autoRotate, timeInterval, rotationInterval } = this.state

		if (!autoRotate) {
			this.stopRotation(true)
		}
	}

	componentHasRef() {
		return this.elementRef.current != null
	}

	bindEventHandlers() {
		this.prev = this.prev.bind(this)
		this.next = this.next.bind(this)
	}

	/**
	 * Checks a number if it is in range.
	 *
	 * @param {Integer} value 		The number to check if in range.
	 * @param {Integer} min 		The minimum range
	 * @param {Integer} max 		The maximum range
	 * @param {Boolean} returnValue Whether to return value or check if number is in range.
	 * @returns 					Returns range if returnValue is set to true. Otherwise, return boolean.
	 */
	intRange(value, min, max, returnValue = false) {
		// Calculate the number is within the inclusive min and max number
		let range = Math.min(Math.max(value, min), max)

		// Check if returnValue is boolean
		if ('boolean' !== typeof returnValue) returnValue = false

		// If range is NaN return
		if (isNaN(range)) return

		if (returnValue) {
			return range
		} else {
			min = parseInt(min)
			max = parseInt(max)

			return range >= min || range <= max ? true : false
		}
	}

	/**
	 * Finds nearest decending number value in every item of an Object with sub-object or Array with sub-object
	 *
	 * @param {Object|Array} data 	The Object with sub-object or Array with sub-object as items
	 * @param {String} key			The property name to be search in every sub-object of an {data}
	 * @param {Number} value		The value of the property to be search in every sub-object of an {data}
	 * @param {String} operator		The Operator to control the nearest number. Allowed {<} or {<=} sign
	 * @returns						Returns an object which is an item from {data}
	 */
	findClosestInt(data, key = '', value, operator = '<') {
		if ('string' !== typeof key || 'number' !== typeof value) return

		// By default that will be a big number
		let closestValue = Infinity

		// We will store the index of the element
		let closestIndex = -1

		// The allowed operators for the operator variable
		let operators = {
			'<=': (a, b) => {
				return a <= b
			},
			'< !': (a, b) => {
				return a < b
			},

			'<': (a, b) => {
				return a < b
			},
			'>': (a, b) => {
				return a > b
			},
		}

		if (Array.isArray(data)) {
			if (Array.prototype.map) {
				data.map((item, index) => {
					// console.log(closestValue)

					var diff = Math.abs(item[key] - value)
					if (operators[operator](diff, closestValue)) {
						closestValue = diff
						closestIndex = index

						// console.table({
						// 	key: key,
						// 	item: item[key],
						// 	value: value,
						// 	operator: operator,
						// 	diff: diff,
						// 	closestIndex: closestIndex,
						// })
					}
				})
			} else {
				for (var index = 0; index < data.length; ++index) {
					var diff = Math.abs(data[index][key] - value)
					if (operators[operator](diff, closestValue)) {
						closestValue = diff
						closestIndex = index
					}
				}
			}

			return data[closestIndex]
		}

		if ('object' === typeof data) {
			for (const prop in data) {
				var diff = Math.abs(data[prop][key] - value)

				if (operators[operator](diff, closestValue)) {
					closestValue = diff
					closestIndex = prop
				}
			}

			return data[closestIndex]
		}
	}

	/**
	 * Wrapper function for Object.keys() which support backwards compatability.
	 *
	 * @param 	{Object}  object 		The object of which the enumerable's own properties are to be returned.
	 * @param 	{Boolean} maintainOrder Whether to maintain the original order of properties in the object.
	 * @returns {Object}  result		An object of strings that represent all the enumerable properties of the given object.
	 */
	objectKeys(object, maintainOrder = true) {
		if (typeof object !== 'object') return

		// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
		if (!Object.keys) {
			Object.keys = (function () {
				'use strict'

				var hasOwnProperty = Object.prototype.hasOwnProperty,
					hasDontEnumBug = !{ toString: null }.propertyIsEnumerable(
						'toString'
					),
					dontEnums = [
						'toString',
						'toLocaleString',
						'valueOf',
						'hasOwnProperty',
						'isPrototypeOf',
						'propertyIsEnumerable',
						'constructor',
					],
					dontEnumsLength = dontEnums.length

				return function (obj) {
					if (
						typeof obj !== 'function' &&
						(typeof obj !== 'object' || obj === null)
					) {
						throw new TypeError('Object.keys called on non-object')
					}

					var result = [],
						prop,
						i

					for (prop in obj) {
						if (hasOwnProperty.call(obj, prop)) {
							result.push(prop)
						}
					}

					if (hasDontEnumBug) {
						for (i = 0; i < dontEnumsLength; i++) {
							if (hasOwnProperty.call(obj, dontEnums[i])) {
								result.push(dontEnums[i])
							}
						}
					}

					if (maintainOrder) result.reverse()

					return result
				}
			})()
		} else {
			let result = Object.keys(object)

			if (maintainOrder) result.reverse()

			return result
		}
	}

	/**
	 * Returns a new object with the values at each key mapped using callback as a callback function.
	 *
	 * @param 	{Object} object		The Object to be map
	 * @param 	{Function} callback	The function to be used as callback
	 * @returns {Object} result		Returns a new object with the values at each key mapped.
	 */
	objectMap(object, callback) {
		return this.objectKeys(object).reduce((result, key) => {
			result[key] = callback(object, key)
			// result[key] = callback(object[key], key)
			return result
		}, {})
	}

	/**
	 * Returns a new array with the values at each key mapped using callback as a callback function.
	 * Wrapper function for Array.prototype.map()
	 *
	 * @param 	{Array} 	data		The array to be map
	 * @param 	{Function}  callback	The function to be used as callback
	 * @link	https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/map
	 * @returns {Array}		result		Returns a new array with the values at each key mapped.
	 */
	arrayMap(data, callback) {
		// Production steps of ECMA-262, Edition 5, 15.4.4.19
		// Reference: https://es5.github.io/#x15.4.4.19
		if (!Array.prototype.map) {
			Array.prototype.map = function (callback /*, thisArg*/) {
				var T, A, k

				if (this == null) {
					throw new TypeError('this is null or not defined')
				}

				// 1. Let O be the result of calling ToObject passing the |this|
				//    value as the argument.
				var O = Object(this)

				// 2. Let lenValue be the result of calling the Get internal
				//    method of O with the argument "length".
				// 3. Let len be ToUint32(lenValue).
				var len = O.length >>> 0

				// 4. If IsCallable(callback) is false, throw a TypeError exception.
				// See: https://es5.github.com/#x9.11
				if (typeof callback !== 'function') {
					throw new TypeError(callback + ' is not a function')
				}

				// 5. If thisArg was supplied, let T be thisArg; else let T be undefined.
				if (arguments.length > 1) {
					T = arguments[1]
				}

				// 6. Let A be a new array created as if by the expression new Array(len)
				//    where Array is the standard built-in constructor with that name and
				//    len is the value of len.
				A = new Array(len)

				// 7. Let k be 0
				k = 0

				// 8. Repeat, while k < len
				while (k < len) {
					var kValue, mappedValue

					// a. Let Pk be ToString(k).
					//   This is implicit for LHS operands of the in operator
					// b. Let kPresent be the result of calling the HasProperty internal
					//    method of O with argument Pk.
					//   This step can be combined with c
					// c. If kPresent is true, then
					if (k in O) {
						// i. Let kValue be the result of calling the Get internal
						//    method of O with argument Pk.
						kValue = O[k]

						// ii. Let mappedValue be the result of calling the Call internal
						//     method of callback with T as the this value and argument
						//     list containing kValue, k, and O.
						mappedValue = callback.call(T, kValue, k, O)

						// iii. Call the DefineOwnProperty internal method of A with arguments
						// Pk, Property Descriptor
						// { Value: mappedValue,
						//   Writable: true,
						//   Enumerable: true,
						//   Configurable: true },
						// and false.

						// In browsers that support Object.defineProperty, use the following:
						// Object.defineProperty(A, k, {
						//   value: mappedValue,
						//   writable: true,
						//   enumerable: true,
						//   configurable: true
						// });

						// For best browser support, use the following:
						A[k] = mappedValue
					}
					// d. Increase k by 1.
					k++
				}

				// 9. return A
				return A
			}
		}

		return data.map((result, index) => {
			result[index] = callback(data, index)
			// result[index] = callback(object[index], index)
			return result
		})
	}

	/**
	 * Fetch object property or nested property using ES6.
	 *
	 * @usage this.getProp({prop1:{prop2:{prop3:'propVal'}} }, 'prop1', 'prop2', 'prop3')
	 *
	 * @shorthand {2019-10-17} allow you to safely access deeply nested properties, by using the token '?.',
	 * 			  the new optional chaining operator:
	 *
	 * 			  Fetch property: obj?.prop1?.prop2?.prop3
	 * 			  Method call: obj?.level1?.method_name();
	 *
	 * @param {Object} obj 			The Object where to fetch the property
	 * @param  {...String} args 	The nested propery names to access using JS 'spread or rest operator'
	 *
	 * @returns {any}		The object property value
	 */
	getProp(obj, ...args) {
		return args.reduce((obj, level) => obj && obj[level], obj)
	}

	/**
	 * Check if an object property or nested property exist.
	 *
	 * @usage this.checkProp({prop1:{prop2:{prop3:'propVal'}} }, 'prop1', 'prop2', 'prop3')
	 *
	 * @param {Object} obj 			The Object where to fetch the property
	 * @param  {...String} level 	The name of direct child propery to access.
	 * @param  {...String} rest 	The nested propery names to access using JS 'spread or rest operator'
	 *
	 * @returns {Boolean}			Returns true if property exist. Otherwise, false.
	 */
	checkProp(obj, level, ...rest) {
		if (obj === undefined) return false
		if (rest.length == 0 && obj.hasOwnProperty(level)) return true
		return this.checkProp(obj[level], ...rest)
	}

	/**
	 * Checks if a URL is a valid URL.
	 *
	 * @param {String} url 	The URL to validate.
	 * @returns 			Returns true if it is a valid URL. Otherwise, false.
	 */
	isValidURL(url) {
		if (typeof url == 'undefined') {
			url = ''
		}

		let regex =
			/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
		let res = url.match(regex)

		return res !== null
	}

	/**
	 * Execute Load Event Listener
	 * @param {function} 		callback  The function to execute
	 * @param {String}   		eventType A case-sensitive string representing the event type to listen for.
	 * @param {Integer|String}  refresh   The setTimeout() refresh time. If set to 'none' no setTimeout() to use.
	 * @param {String}   		option	  An object that specifies characteristics about the event listener.
	 * @see   https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
	 */
	eventHandler = (callback, eventType, refresh = 50, option = false) => {
		// Make sure a valid callback was provided
		if (!callback || typeof callback !== 'function') return

		if ('' === callback || typeof eventType !== 'string') return

		// Setup eventHandler variable
		let eventHandler

		// Listen for event
		window.addEventListener(
			eventType,
			function (e) {
				// Clear our timeout throughout the event
				window.clearTimeout(eventHandler)

				if ('none' === refresh) {
					eventHandler = callback()
				} else {
					// Set a timeout to run after event ends
					eventHandler = setTimeout(callback, refresh)
				}
			},
			option
		)
	}

	/**
	 * Execute Load Event Listener
	 * @param {function} 		callback  The function to execute
	 * @param {Integer|String}  refresh   The setTimeout() refresh time. If set to 'none' no setTimeout() to use.
	 * @param {String}   		option	  An object that specifies characteristics about the event listener.
	 * @see   this.eventHandler()
	 */
	onLoad = (callback, refresh = 50, option = false) => {
		this.eventHandler(callback, 'load', refresh, option)
	}

	/**
	 * Execute Scroll Event Listener
	 * @param {function} 		callback  The function to execute
	 * @param {Integer|String}  refresh   The setTimeout() refresh time. If set to 'none' no setTimeout() to use.
	 * @param {String}   		option	  An object that specifies characteristics about the event listener.
	 * @see   this.eventHandler()
	 */
	onScroll = (callback, refresh = 50, option = false) => {
		this.eventHandler(callback, 'scroll', refresh, option)
	}

	/**
	 * Execute Resize Event Listener
	 * @param {function} 		callback  The function to execute
	 * @param {Integer|String}  refresh   The setTimeout() refresh time. If set to 'none' no setTimeout() to use.
	 * @param {String}   		option	  An object that specifies characteristics about the event listener.
	 * @see   this.eventHandler()
	 */
	onResize = (callback, refresh = 50, option = false) => {
		this.eventHandler(callback, 'resize', refresh, option)
	}

	execTimeout = (callback, timeout = 1000) => {
		// Make sure a valid callback was provided
		if (!callback || typeof callback !== 'function') return

		if ('' === callback) return

		// Set a timeout to run after event ends
		// this.timeoutHandler = setTimeout(callback, timeout)
		this.timeoutHandler = setTimeout(
			function () {
				callback()
			}.bind(this),
			timeout
		)

		// If debug is enabled
		if (this.state.debug) {
			console.log(`Timeout Interval: ${this.timeoutHandler}`)
		}

		if (this.resetTimeoutInterval <= this.timeoutHandler) {
			clearTimeout(this.timeoutHandler)
		}
	}

	getTheta = (items) => {
		var theta = []
		var frags = 360 / items
		for (var i = 0; i <= items; i++) {
			theta.push((frags / 180) * i * Math.PI)
		}

		return theta
	}

	setPositions = (roatation) => {
		let { prevItem, activeItem, nextItem, lastItem } = this.state

		lastItem = lastItem - 1

		let calculate = {
			'+': function (a, b) {
				return a + b
			},
			'-': function (a, b) {
				return a - b
			},
		}
		let operator = null

		let itemActive = activeItem
		let itemNext = document.querySelectorAll(
			`#${this.uniqueID} .carousel .item-carousel.next`
		)
		let itemPrev = document.querySelectorAll(
			`#${this.uniqueID} .carousel .item-carousel.prev`
		)

		if ('prev' === roatation) {
			operator = '-'
			itemActive = itemActive - 1
		} else {
			operator = '+'
			itemActive = itemActive + 1
		}

		if (null !== operator) {
			// If element exist get data-count else set value to lastItem
			if (itemPrev.length !== 0) {
				itemPrev = itemPrev[0].getAttribute('data-count')
				itemPrev = parseInt(itemPrev)
			} else {
				itemPrev = lastItem
			}

			// If itemPrev value is not equal to zero
			if (0 !== itemPrev) {
				if (itemPrev > lastItem) {
					itemPrev = 0
				} else {
					// If operator is set to negative itemPrev minus 1.
					// If operator is set to positive itemPrev plus 1.
					itemPrev = calculate[operator](itemPrev, 1)
				}
			} else {
				itemPrev = 1
			}

			// If element exist get data-count else set value to activeItem plus 1
			if (itemNext.length !== 0) {
				itemNext = itemNext[0].getAttribute('data-count')
				itemNext = parseInt(itemNext)
			} else {
				// itemNext = activeItem + 1
				itemNext = calculate[operator](activeItem, 1)
			}

			// If itemNext value is not equal to zero
			if (0 !== itemNext) {
				if (itemNext > lastItem) {
					itemNext = 0
				} else {
					// If operator is set to negative itemPrev minus 1.
					// If operator is set to positive itemPrev plus 1.
					itemNext = calculate[operator](itemNext, 1)
				}
			} else {
				itemNext = 1
			}

			// Counter the positions for Prev
			if ('prev' === roatation) {
				itemNext = itemActive + 1

				if (itemPrev === 0 && itemActive < 0) {
					itemActive = lastItem
				} else if (itemActive === lastItem && itemNext > lastItem) {
					itemNext = 0
				} else if (itemActive < 0) {
					itemActive = lastItem
				} else if (itemActive === 0 && itemPrev !== lastItem) {
					itemPrev = lastItem
				}
			}

			// Counter the positions for Next
			if ('next' === roatation) {
				if (itemPrev > lastItem) {
					itemPrev = itemActive - 1
				} else if (itemActive === lastItem && itemNext > lastItem) {
					itemNext = 0
				} else if (itemActive > lastItem) {
					itemPrev = lastItem
					itemActive = 0
					itemNext = 1
				} else if (itemActive < 0) {
					itemActive = 1
				}
			}
		}

		if (!itemPrev && typeof itemPrev !== 'number') itemPrev = lastItem
		if (!itemActive && typeof itemActive !== 'number')
			itemActive = activeItem
		if (!itemNext && typeof itemNext !== 'number') itemNext = activeItem + 1

		// console.table({
		// 	prevItem: this.state.prevItem,
		// 	activeItem: this.state.activeItem,
		// 	nextItem: this.state.nextItem,
		// })

		return {
			prev: itemPrev,
			active: itemActive,
			next: itemNext,
		}
	}

	prev = () => {
		let circle = 360
		let items = this.state.items.length
		let adjustment = Math.floor(circle / items)

		let position = this.setPositions('prev')

		this.setState((state) => ({
			carouselDeg: state.carouselDeg + adjustment,

			itemDeg: state.itemDeg - adjustment,

			prevItem: position.prev,
			activeItem: position.active,
			nextItem: position.next,
			animate: !this.state.animate,
		}))
	}

	next = () => {
		let circle = 360
		let items = this.state.items.length
		let adjustment = circle / items
		// adjustment = Math.floor(circle / items)

		let position = this.setPositions('next')

		this.setState((state) => ({
			carouselDeg: state.carouselDeg - adjustment,

			itemDeg: state.itemDeg + adjustment,

			prevItem: position.prev,
			activeItem: position.active,
			nextItem: position.next,
			animate: !this.state.animate,
		}))
	}

	autoRotation = (controller) => {
		const { autoRotate, timeInterval } = this.state

		let getAutoRotate = autoRotate || 'next'
		let getTimeInterval = timeInterval

		if (!controller) {
			this.state.stopRotation = true
			clearInterval(this.state.rotationInterval)
		} else {
			if (false !== getAutoRotate && this.componentHasRef()) {
				this.state.stopRotation = false

				this.state.rotationInterval = setInterval(() => {
					if (getAutoRotate === 'prev') {
						this.prev()
					} else {
						if (getAutoRotate === 'next' || getAutoRotate) {
							this.next()
						}
					}
				}, getTimeInterval)
			}
		}

		return
	}

	stopRotation = (controller) => {
		const { autoRotate } = this.state

		controller = controller ? false : true

		if (false === autoRotate) controller = false

		this.autoRotation(controller)
	}

	getItemClass = (id) => {
		const { activeItem, nextItem, prevItem, lastItem } = this.state
		let className = `item-${id}`

		if (id === activeItem) {
			className += ' active'
		} else if (id === nextItem) {
			className += ' next'
		} else if (id === prevItem) {
			className += ' prev'
		} else {
			className += ' pending'
		}

		return className
	}

	getItemTransformStyle = (index) => {
		let itemSize = this.state.itemSize
		let items = this.state.items.length
		let carouselSize = this.state.carouselSize
		let carouselRadius = carouselSize / 2
		let itemDeg = this.state.itemDeg
		let itemTranslate = carouselRadius

		let theta = this.getTheta(items)
		let axisY = Math.round(carouselRadius * Math.sin(theta[index]))
		let axisX = Math.round(carouselRadius * Math.cos(theta[index]))

		axisY = (carouselSize - itemSize) / 2 //+ axisY - axisY
		axisX = (carouselSize - itemSize) / 2 //+ axisX - axisX

		let pi = Math.PI
		let circle = 360
		let offsetAngle = circle / items
		let rotateAngle = offsetAngle * index
		let style = ''

		// Counter the itemDeg rotation
		itemDeg = itemDeg - rotateAngle

		// let adjustment = (carouselSize / items / pi) * 2
		// offsetAngle = circle / items / pi
		// rotateAngle = offsetAngle * index
		// rotateAngle = adjustment

		// rotateAngle = carouselRadius / (items / 2) - itemSize / 2
		// rotateAngle = carouselRadius / 3 - 40
		// rotateAngle = (rotateAngle * theta[index]) / pi

		// rotateAngle = carouselSize / theta[items]
		// rotateAngle = rotateAngle - itemSize / 2
		// rotateAngle = rotateAngle * theta[index]

		// rotateAngle = rotateAngle - theta[items]

		// console.log({
		// 	rotateAngle: rotateAngle,
		// 	theta: theta[index],
		// 	thetaY: Math.sin(theta[index]),
		// 	thetaX: Math.cos(theta[index]),
		// })

		style = {
			width: `${itemSize}px`,
			height: `${itemSize}px`,
			transform: `
			rotate(${rotateAngle}deg) 
			translate( 0, -${itemTranslate}px) 
			rotate(${itemDeg}deg)`,
			top: `${axisY}px`,
			left: `${axisX}px`,
		}

		return style
	}

	/**
	 * Use this method to execute breakpoints configuration for the carousel.
	 */
	execResponsive = (isResizing = false) => {
		// Create a mutable copy of {breakpoints} object property
		let breakpoints = this.breakpoints
		let updatedBreakpoint = ''

		if (breakpoints.length !== 0) {
			let configs = {},
				deviceHeight = window.innerHeight,
				deviceWidth = window.innerWidth

			configs = this.findClosestInt(
				breakpoints,
				'breakpoint',
				deviceWidth,
				'<'
			)

			if (this.checkProp(configs, 'breakpoint')) {
				updatedBreakpoint = `${this.getProp(configs, 'breakpoint')}`
			}

			// Execute if {configs} does not have 'items' property
			if (!this.checkProp(configs, 'items')) {
				configs = Object.assign(
					{
						items: this.props.config.items,
					},
					configs
				)
			}

			// Re-render the properties
			this.setProperties(configs)

			/**
			 * Execute an empty {this.setState()} call to apply the changes from {this.setProperties()}
			 */
			this.setState((state) => ({
				currentBreakpoint: updatedBreakpoint,
				activeItem: 0,
				prevItem: configs.items.length - 1,
				nextItem: 1,
				lastItem: configs.items.length,
				carouselDeg: 0,
				itemDeg: 0,
			}))
		}
	}

	/**
	 * Merge arrays or objects
	 *
	 * @param 	{Array|Object} 	arguments The array or object to be map
	 *
	 * @demo var $arr1 = {"color": "red", 0: 2, 1: 4}
	 *       var $arr2 = {0: "a", 1: "b", "color": "green", "shape": "trapezoid", 2: 4}
	 *       this.arrayMerge($arr1, $arr2)
	 *       returns: {"color": "green", 0: 2, 1: 4, 2: "a", 3: "b", "shape": "trapezoid", 4: 4}
	 *
	 * @demo var $arr1 = []
	 *       var $arr2 = {1: "data"}
	 *       pmt_array_merge($arr1, $arr2)
	 *       returns: {0: "data"}
	 */
	arrayMerge(...datas) {
		const args = Array.prototype.slice.call(datas)
		const argl = args.length
		let arg
		const retObj = {}
		let k = ''
		let argil = 0
		let j = 0
		let i = 0
		let ct = 0
		const toStr = Object.prototype.toString
		let retArr = true

		// loop the argl
		for (i = 0; i < argl; i++) {
			// check if args is not '[object Array]'
			if (toStr.call(args[i]) !== '[object Array]') {
				retArr = false
				break
			}
		}

		// If retArr is true
		if (retArr) {
			// Set value to empty array
			retArr = []

			// Loop the argl and contatinate the args value to retArr
			for (i = 0; i < argl; i++) {
				retArr = retArr.concat(args[i])
			}
			return retArr
		}

		for (i = 0, ct = 0; i < argl; i++) {
			arg = args[i]

			if (toStr.call(arg) === '[object Array]') {
				for (j = 0, argil = arg.length; j < argil; j++) {
					retObj[ct++] = arg[j]
				}
			} else {
				for (k in arg) {
					if (arg.hasOwnProperty(k)) {
						if (parseInt(k, 10) + '' === k) {
							retObj[ct++] = arg[k]
						} else {
							retObj[k] = arg[k]
						}
					}
				}
			}
		}

		return retObj
	}

	/**
	 * Generates the X & Y axis value for the target element on the page.
	 *
	 * @param {Object} el	The target element.
	 * @returns
	 */
	getOffset(el) {
		var _x = 0
		var _y = 0
		while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
			_x += el.offsetLeft - el.scrollLeft
			_y += el.offsetTop - el.scrollTop
			el = el.offsetParent
		}
		return { horizontal: _y, vertical: _x }
	}

	/**
	 * Scrolls the page to the target element
	 *
	 * @param {Object} e 		The event handler element.
	 * @param {Object} args 	The method configurations for the page auto scroll.
	 */
	scrollToElement(e, args = {}) {
		e.preventDefault()

		let defaults = {
			selector: selector,
			offset: 100,
		}

		args = this.arrayMerge(defaults, args)

		let { selector, offset } = args

		let target = document.querySelector(selector)

		if (target) {
			let targetY = this.getOffset(target).horizontal

			targetY -= offset

			window.scrollTo(0, targetY)
		}
	}

	/**
	 * Use this method to execute the {this.execResponsive()} on resize.
	 */
	execOnResize = () => {
		// Create a mutable copy of {breakpoints} object property
		let breakpoints = this.breakpoints

		// Check if {breakpoints} has items
		if (breakpoints.length !== 0) {
			// Execute resize event handler
			this.onResize(() => {
				this.execResponsive(true)
			}, '500')
		}
	}

	getControls = () => {
		// onClick onMouseOver

		let { navClass } = this.controlsConfig
		let btnClass = navClass ? `ctrl-btn ${navClass}` : 'ctrl-btn'

		return (
			<div className="carousel-controls">
				<button onClick={this.prev} className={`${btnClass} prev`}>
					Prev
				</button>
				<button onClick={this.next} className={`${btnClass} next`}>
					Next
				</button>
			</div>
		)
	}

	renderItemContent = (data = {}, contentType = 'items') => {
		// Object destructuring of variable data
		const {
			_title = '',
			_link = '',
			_icon = '',
			_bgColor = '',
			_bgImage = '',
		} = data

		// Create mutable copies and use as main reference
		let elementItemClass = ''
		let dataTitle = _title
		let dataLink = _link
		let dataIcon = _icon
		let dataBgColor = _bgColor
		let dataBgImage = _bgImage

		// Check if data values are acceptable. Otherwise, return.
		if (
			(dataTitle === undefined && typeof dataTitle !== 'string') ||
			(dataLink === undefined && typeof dataLink !== 'string') ||
			(dataIcon === undefined && typeof dataIcon !== 'string') ||
			(dataBgColor === undefined && typeof dataBgColor !== 'string') ||
			(dataBgImage === undefined && typeof dataBgImage !== 'string')
		)
			return

		// Setup the config type to process
		let config = {}
		if ('items' === contentType) {
			config = this.itemConfig
			elementItemClass = 'item-inner'
		}
		if ('activeItem' === contentType) {
			config = this.activeConfig
			elementItemClass = 'content-inner'
		}

		// Assign the itemConfig values
		let confTitle = config.title
		let confLink = config.link
		let confIcon = config.icon
		let confBgColor = config.backgroundColor
		let confBgImage = config.backgroundImage

		// Initialize markup variables
		let domTitle = ''
		let domLink = ''
		let domIcon = ''
		let domContent = ''
		let domItem = ''

		// If _link is empty set default
		if ('' === dataLink) dataLink = '#'

		// Build the title markup
		if (confTitle) domTitle = <span className="title">{dataTitle}</span>

		// Build the icon markup
		if (confIcon) domIcon = <img className="icon" src={dataIcon} />

		// Build link markup
		domLink = () => {
			if (this.isValidURL(dataLink)) {
				return (
					<a href={`${dataLink}`} className="item-link item-data">
						{domIcon}
						{domTitle}
					</a>
				)
			} else {
				let onClickHandler = (e, _selector) => {
					if (typeof e === 'object') {
						e.preventDefault()

						this.scrollToElement(e, {
							selector: _selector,
							offset: 100,
						})
					}
				}

				return (
					<Link
						to={`${dataLink}`}
						onClick={(e) => onClickHandler(e, dataLink)}
						className="item-link item-data"
					>
						{domIcon}
						{domTitle}
					</Link>
				)
			}
		}

		// Build Content
		domContent = () => {
			// If link itemConfig is true
			if (confLink) {
				return <>{domLink()}</>
			} else {
				return (
					<span className="item-data">
						{domTitle}
						{domIcon}
					</span>
				)
			}
		}

		// Build Item
		domItem = () => {
			let cssStyle = {}
			let elementClass = elementItemClass
			let configClass = ''

			if (confBgColor) {
				if ('' !== dataBgColor) {
					configClass = ' has-bg-color'
					cssStyle.backgroundColor = dataBgColor
				}
			}

			if (confBgImage) {
				if ('' !== dataBgImage) {
					configClass = ' has-bg-image'
					cssStyle.backgroundImage = `url(${dataBgImage})`
				}
			}

			elementClass += configClass

			return (
				<div className={elementClass} style={cssStyle}>
					{domContent()}
				</div>
			)
		}

		return domItem()
	}

	getActiveContent = () => {
		const { items, activeItem, activeContentSize } = this.state

		let { iconProxy } = this.activeConfig

		let carouselSize = this.state.carouselSize
		let contentSize = 0
		let axis = 0

		if ('number' === typeof activeContentSize) {
			contentSize = activeContentSize
			axis = activeContentSize
		} else {
			contentSize = carouselSize / 2
			axis = contentSize / 2
		}

		// let content = ''
		// let title = ''

		// if (this.checkProp(items[activeItem], 'title')) {
		// 	title = this.getProp(items[activeItem], 'title')
		// } else {
		// 	title = this.getProp(items[activeItem], 'title')
		// }
		// content = title

		let return_markup = false

		let itemTitle = this.getProp(items[activeItem], 'title')
		let itemLink = this.getProp(items[activeItem], 'link')
		let itemIcon = this.getProp(items[activeItem], 'icon')
		let itemIconProxy = this.getProp(items[activeItem], 'iconProxy')
		let itemBgColor = this.getProp(items[activeItem], 'backgroundColor')
		let itemBgImage = this.getProp(items[activeItem], 'backgroundImage')
		let useIcon = !iconProxy ? itemIcon : itemIconProxy

		if (
			this.checkProp(items[activeItem], 'title') ||
			this.checkProp(items[activeItem], 'link') ||
			this.checkProp(items[activeItem], 'icon') ||
			this.checkProp(items[activeItem], 'iconProxy') ||
			this.checkProp(items[activeItem], 'backgroundColor') ||
			this.checkProp(items[activeItem], 'backgroundImage')
		)
			return_markup = true

		if (return_markup) {
			return (
				<div
					className="active-content"
					style={{
						width: `${contentSize}px`,
						height: `${contentSize}px`,
						// minWidth: `${contentSize}px`,
						// minHeight: `${contentSize}px`,
						// top: `${axis}px`,
						// left: `${axis}px`,
					}}
				>
					{/* <div className="content-inner">{content}</div> */}

					{this.renderItemContent(
						{
							_title: itemTitle,
							_link: itemLink,
							_icon: useIcon,
							_bgColor: itemBgColor,
							_bgImage: itemBgImage,
						},
						'activeItem'
					)}
				</div>
			)
		}
	}

	getItem = (item, index) => {
		if (
			(item === undefined && typeof item !== 'object') ||
			(index === undefined && typeof index !== 'string')
		)
			return false

		let return_markup = false

		let itemTitle = this.getProp(item, 'title')
		let itemLink = this.getProp(item, 'link')
		let itemIcon = this.getProp(item, 'icon')
		let itemBgColor = this.getProp(item, 'backgroundColor')
		let itemBgImage = this.getProp(item, 'backgroundImage')

		if (
			this.checkProp(item, 'title') ||
			this.checkProp(item, 'link') ||
			this.checkProp(item, 'icon') ||
			this.checkProp(item, 'backgroundColor') ||
			this.checkProp(item, 'backgroundImage')
		)
			return_markup = true

		if (return_markup) {
			return (
				<div
					className={`item-carousel ${this.getItemClass(index)} ${
						this.state.animate ? 'animate' : ''
					}`}
					data-count={index}
					key={index}
					style={this.getItemTransformStyle(index)}
				>
					{this.renderItemContent(
						{
							_title: itemTitle,
							_link: itemLink,
							_icon: itemIcon,
							_bgColor: itemBgColor,
							_bgImage: itemBgImage,
						},
						'items'
					)}
				</div>
			)
		} else {
			return
		}
	}

	render() {
		let main_wrapper_class = 'circle-carousel-wrapper'

		// Add class if debug is enabled
		main_wrapper_class += this.state.debug ? ' debug-on' : ''
		main_wrapper_class += this.state.carouselClassnames
			? ` ${this.state.carouselClassnames}`
			: ''
		main_wrapper_class += this.extraClasses

		return (
			<div
				ref={this.elementRef}
				id={this.uniqueID}
				className={main_wrapper_class}
				onMouseEnter={() => this.stopRotation(true)}
				onMouseLeave={() => this.stopRotation(false)}
				data-breakpoint={this.state.currentBreakpoint}
			>
				<div className="carousel-inner-wrap">
					{this.getControls()}
					{this.getActiveContent()}

					<div
						className="carousel-wrap"
						style={{
							minWidth: `${this.state.carouselWrapperSize}px`,
							minHeight: `${this.state.carouselWrapperSize}px`,
						}}
					>
						<div
							className="carousel"
							style={{
								width: `${this.state.carouselSize}px`,
								height: `${this.state.carouselSize}px`,
								transform: `rotate(${this.state.carouselDeg}deg)`,
							}}
						>
							<div className="carousel-inner">
								{this.state.items.map((item, index) =>
									this.getItem(item, index)
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Set default properties for CircularCarouselComponent()
 */
CircularCarouselComponent.defaultProps = {
	config: {
		carouselSize: 560, //360,
		carouselDeg: 0, //360,
		carouselWrapperSize: 760,
		itemSize: 120,
		itemDeg: 0, //360,
		activeContentSize: 1.5,
		debug: true,
		clone: false,
		autoRotate: false, // 'prev' or 'next' or if true rotate to next. Otherwise, autoRotate set to off
		timeInterval: 500,
		items: [],
	},
}

export default CircularCarouselComponent
