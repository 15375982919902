import React, { Component } from 'react'

import CircularCarouselHOC from './CircularCarouselHOC'
import CircularCarouselComponent from './CircularCarouselComponent.js'

const CircularCarouselComponentHOC = CircularCarouselHOC(
	CircularCarouselComponent
)

function CircularCarousel(props) {
	let strictMode = props.config.strictMode || true

	if (strictMode) {
		return (
			<React.StrictMode>
				<CircularCarouselComponentHOC {...props} />
			</React.StrictMode>
		)
	} else {
		return <CircularCarouselComponentHOC {...props} />
	}
}

export default CircularCarousel
