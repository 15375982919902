// Import Helper functions
import Helpers from './helpers/Helpers'

import StickyHeader from './interfaces/header/Sticky'

export class Init {
	constructor() {
		this.StickyHeader = new StickyHeader()
	}

	exec(state, dispatch) {
		this.state = state
		this.dispatch = dispatch

		// Handles Windows on Load
		Helpers.onLoad(() => {
			// this.dispatch({ type: 'initGlovars' })
			this.StickyHeader.init()
		})

		// Handles Windows on Scroll
		Helpers.onScroll(() => {
			// this.dispatch({ type: 'reloadGlovars', option: ['header'] })

			this.StickyHeader.init()
		}, 'none')

		// Handles Windows on Resize
		Helpers.onResize(() => {
			// this.dispatch({ type: 'reloadGlovars', option: ['header'] })
		})
	}

	setPageTitle(title) {
		if (title) {
			document.title = `${title} | Grow Power Support`
			window.scrollTo(0, 0)
		}
	}
}
export default Init

// function Init(props) {
// 	const appState = useContext(StateContext)
// 	const appDispatch = useContext(DispatchContext)

// 	// Sets the page title
// 	if (props.title) {
// 		useEffect(() => {
// 			document.title = `${props.title} | Grow Power Support`
// 			window.scrollTo(0, 0)
// 		}, [props.title])
// 	}

// 	// Handles Windows on Load
// 	Helpers.onLoad(() => {
// 		appDispatch({ type: 'initGlovars' })
// 	})

// 	// Handles Windows on Scroll
// 	Helpers.onScroll(() => {
// 		appDispatch({ type: 'reloadGlovars', option: ['header'] })
// 		appDispatch({ type: 'initStickyHeader' })
// 	})

// 	// Handles Windows on Resize
// 	Helpers.onResize(() => {
// 		appDispatch({ type: 'reloadGlovars', option: ['header'] })
// 	})
// }

// export default Init
