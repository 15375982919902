import React, { useEffect, useContext } from 'react'

import { Link } from 'react-router-dom'

import {
	StateContext,
	DispatchContext,
	SiteWrapper,
	Section,
} from '../../containers'

// Interfaces
import { CircularCarousel, SlickCarousel, Gridz } from '../../components'

function Home(props) {
	const appState = useContext(StateContext)
	const appDispatch = useContext(DispatchContext)

	const carouselConfig = {
		carouselClassnames: 'banner-carousel',
		debug: false,
		autoRotate: false,
		timeInterval: 500,

		controlsConfig: {
			navClass: 'gp-btn-ripple',
		},

		activeConfig: {
			title: true,
			icon: true,
			iconProxy: true,
			link: true,
			backgroundColor: true,
			backgroundImage: true,
		},
		itemConfig: {
			title: false,
			icon: true,
			link: true,
			backgroundColor: true,
			backgroundImage: true,
		},
		items: [
			{
				title: 'Websites',
				link: '#portfolios-section', // 'https://growpowersupport.com/websites',
				icon: '/images/carousel/web-apps.svg',
				iconProxy: '/images/carousel/web-apps-light.svg',
				// backgroundImage: '/images/logo.svg',
			},
			{
				title: 'Web Apps',
				link: '#portfolios-section', //'https://www.growpowersupport.com/web-applications',
				icon: '/images/carousel/app.svg',
				iconProxy: '/images/carousel/app-light.svg',
			},
			{
				title: 'Mobile Apps',
				link: '#portfolios-section', //'https://www.growpowersupport.com/mobile-apps',
				icon: '/images/carousel/mobile.svg',
				iconProxy: '/images/carousel/mobile-light.svg',
				// backgroundColor: 'violet',
			},
			{
				title: 'Software Dev',
				link: '#portfolios-section', //'https://www.growpowersupport.com/software-development',
				icon: '/images/carousel/calculator.svg',
				iconProxy: '/images/carousel/calculator-light.svg',
			},
			{
				title: 'Sales & Analytics',
				link: '#portfolios-section', //'https://www.growpowersupport.com/marketing',
				icon: '/images/carousel/statistics.svg',
				iconProxy: '/images/carousel/statistics-light.svg',
			},
			{
				title: 'Project Management',
				link: '#portfolios-section', //'https://www.growpowersupport.com/pm',
				icon: '/images/carousel/briefcase.svg',
				iconProxy: '/images/carousel/briefcase-light.svg',
			},
		],
		breakpoints: [
			{
				breakpoint: 320,
				carouselSize: 250,
				carouselWrapperSize: 600,
				activeContentSize: 120,
				itemSize: 80,
			},
			{
				breakpoint: 370,
				carouselSize: 300,
				carouselWrapperSize: 600,
				activeContentSize: 120,
				itemSize: 80,
			},
			{
				breakpoint: 380,
				carouselSize: 320,
				carouselWrapperSize: 800,
				activeContentSize: 150,
				itemSize: 80,
			},
			{
				breakpoint: 480,
				carouselSize: 400,
				carouselWrapperSize: 800,
				activeContentSize: 150,
				itemSize: 80,
			},
			{
				breakpoint: 500,
				carouselSize: 400,
				carouselWrapperSize: 800,
				activeContentSize: 200,
				itemSize: 100,
			},
			{
				breakpoint: 600,
				carouselSize: 400,
				carouselWrapperSize: 800,
				activeContentSize: 200,
				itemSize: 100,
			},
			{
				breakpoint: 680,
				carouselSize: 400,
				carouselWrapperSize: 800,
				activeContentSize: 200,
				itemSize: 100,
			},
			{
				breakpoint: 768,
				carouselSize: 550,
				carouselWrapperSize: 920,
				activeContentSize: 200,
				itemSize: 100,
			},
			{
				breakpoint: 800,
				carouselSize: 560,
				carouselWrapperSize: 1080,
				activeContentSize: 200,
				itemSize: 120,
			},
			{
				breakpoint: 992,
				carouselSize: 560,
				carouselWrapperSize: 1080,
				activeContentSize: 200,
				itemSize: 120,
			},
			{
				breakpoint: 993,
				carouselSize: 460,
				carouselWrapperSize: 700,
				activeContentSize: 200,
				itemSize: 120,
			},
			{
				breakpoint: 1200,
				carouselSize: 520,
				carouselWrapperSize: 920,
				activeContentSize: 200,
				itemSize: 120,
			},
			{
				breakpoint: 1400,
				carouselSize: 560,
				carouselWrapperSize: 960,
				activeContentSize: 200,
				itemSize: 120,
			},
		],
	}

	const testimonialsCarouselConfig = {
		className: 'testimonial-carousel container-lg',

		dots: true,
		infinite: true,
		swipeToSlide: true,

		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		centerPadding: '60px',
		slideContentPosition: 'center',

		autoplay: true,
		autoplaySpeed: 5000,
		pauseOnHover: true,
		cssEase: 'ease-in-out',

		items: [
			{
				title: 'Phil McGilvray',
				image: '/images/testimonials/phil_mc.jpg',
				subtitle: 'Founder',
				description:
					'It was great working with GrowPower. The best compliment I can give is the fact that we have immediately contracted them to do phase 2 of our project.',
			},
			{
				title: 'James Laska',
				image: '/images/testimonials/james_laska.jpg',
				subtitle: 'Founder',
				description:
					'GrowPower did an amazing job building my e-commerce website and helping me establish an affiliate program. They are great to work with.',
			},
			{
				title: 'Tracey Thomsett',
				image: '/images/testimonials/tracy.jpeg',
				subtitle: 'Owner',
				description:
					'GrowPower has been an enormous help in both designing our website and the promotion of it. They are now my first point of contact when I have an internet/software query.',
			},
			{
				title: 'Matt Laidlaw',
				image: '/images/testimonials/matt_laidlaw.jpg',
				subtitle: 'Director',
				description:
					'GrowPower has been awesome to work with. Because of my satisfaction and trust in their work, I will continue to send all my jobs their way.',
			},
			{
				title: 'Max Mallory',
				image: '/images/testimonials/max_mallory.png',
				subtitle: 'Manager',
				description:
					'All in all, I would say GrowPower has been an immense asset and I would recommend their services any time.',
			},
			{
				title: 'Ginger Berman',
				image: '/images/testimonials/ginger_berman.jpeg',
				subtitle: 'Owner',
				description:
					'GrowPower designed my event planning business website when I needed a solution to manage my own content. They listened to my needs for the site and incorporated them with a clean design for which I still receive compliments.',
			},
			{
				title: 'Gianluca Colla',
				image: '/images/testimonials/gianluca_colla.jpg',
				subtitle: 'Owner, Founder',
				description:
					'I have rarely worked with someone with such a deep knowledge of the subject that can fully understand the project and deliver. Working with GrowPower will save you time and money in meeting your goals.',
			},
			{
				title: 'Tete Sedalo',
				image: '/images/testimonials/tete_sedalo.jpg',
				subtitle: 'Owner, Director',
				description:
					'We have found our guru! Results are above expectations. We are more than satisfied and this has become a long-term professional relationship.',
			},
			{
				title: 'Todd Clary',
				image: '/images/testimonials/todd_clary.jpg',
				subtitle: 'Owner, Director',
				description:
					'GrowPower has this way of seeing things overall and somehow mapping out several contingencies. Their forward-thinking and anticipation of technical aspects are worth their weight in gold. If you want the best, hire GrowPower.',
			},
			{
				title: 'Samuel Junghenn',
				image: '/images/testimonials/sam_junghenn.jpeg',
				subtitle: 'Owner',
				description:
					'GrowPower are very professional in everything that they do. I have used their services more than once and will do so in the future again.',
			},
			{
				title: 'Peter Osbourne',
				image: '/images/testimonials/peter_osb.jpeg',
				subtitle: 'Owner',
				description:
					'GrowPower has designed and developed two complex websites for my online businesses. They have a thorough understanding of design and do high-quality work.',
			},

			// {
			// 	title: 'Georgie Matieneo',
			// 	image: '/images/testimonials/person_1.jpg',
			// 	subTitle: 'Manager',
			// 	description:
			// 		'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec sodales justo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In orci eros, dictum vitae libero sit amet, feugiat ultricies arcu. Phasellus non risus in tellus tempor ultrices et a risus. Cras libero massa, efficitur vitae justo in, dapibus mollis leo. Phasellus sed lorem magna. Quisque varius sapien ac ultrices pretium. Morbi pretium molestie felis et dapibus.',
			// },
			// {
			// 	title: 'Andrew Kelsek',
			// 	image: '/images/testimonials/person_2.jpg',
			// 	description:
			// 		'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec sodales justo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In orci eros, dictum vitae libero sit amet, feugiat ultricies arcu. Phasellus non risus in tellus tempor ultrices et a risus. Cras libero massa, efficitur vitae justo in, dapibus mollis leo. Phasellus sed lorem magna. Quisque varius sapien ac ultrices pretium. Morbi pretium molestie felis et dapibus.',
			// },
			// {
			// 	title: 'Anna Schnider',
			// 	image: '/images/testimonials/person_3.jpg',
			// 	description:
			// 		'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec sodales justo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In orci eros, dictum vitae libero sit amet, feugiat ultricies arcu. Phasellus non risus in tellus tempor ultrices et a risus. Cras libero massa, efficitur vitae justo in, dapibus mollis leo. Phasellus sed lorem magna. Quisque varius sapien ac ultrices pretium. Morbi pretium molestie felis et dapibus.',
			// },
			// {
			// 	title: 'Muhamad Abubaker',
			// 	image: '/images/testimonials/person_4.jpg',
			// 	description:
			// 		'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec sodales justo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In orci eros, dictum vitae libero sit amet, feugiat ultricies arcu. Phasellus non risus in tellus tempor ultrices et a risus. Cras libero massa, efficitur vitae justo in, dapibus mollis leo. Phasellus sed lorem magna. Quisque varius sapien ac ultrices pretium. Morbi pretium molestie felis et dapibus.',
			// },
		],
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
				},
			},
		],
		// slidesMarkup: () => {
		// 	return testimonialsCarouselConfig.items.map(
		// 		({ title, image }, index) => (
		// 			<div key={index}>
		// 				<img src={image} />
		// 				<h3>{title}</h3>
		// 			</div>
		// 		)
		// 	)
		// },
	}

	const portfoliosGridzConfig = {
		className: 'portfolios-grid container-lg',
		lightboxType: 'minimal',
		alternateReverseRow: true,
		debug: true,
		items: [
			{
				title: 'Funky Furniture',
				backgroundImage: '/images/portfolios/funky.jpg',
				lightbox: {
					featuredImage: '/images/portfolios/funky.jpg',
					content:
						'<p> Funky Furniture came to us with a clunky e-commerce website that failed to capture their unique sense of style and fun. Their unique selling point is that every piece is made to order, but their website did not allow shoppers to customize their orders. We designed and built a web application that reflects the Funky Furniture personality and allows shoppers to custom design their furniture in just a few clicks.</p>',
				},
			},

			{
				title: 'Weight Loss Buddy',
				backgroundImage: '/images/portfolios/weight.jpg',
				lightbox: {
					featuredImage: '/images/portfolios/weight.jpg',
					content:
						'<p> Weight Loss Buddy came to us with an idea and a vision. Weight loss is hard. It would be much easier if you had a buddy to reach out to when that chocolate cake temptation arises! We designed and built a social network and companion mobile application that allows users to connect to other users and nominate one as a buddy. When temptation hits a buddy is just one click away!.</p>',
				},
			},

			{
				title: 'Savsani',
				backgroundImage: '/images/portfolios/savsani.jpg',
				lightbox: {
					featuredImage: '/images/portfolios/savsani.jpg',
					content:
						'<p> Savsani provide corporate wellness solutions. They needed a website that visually communicated their core values of simplicity, mindfulness, and balance. They wanted a design that reflected both the natural world and our place in it. We are pleased to say the result was a resounding success.</p>',
				},
			},

			{
				title: 'Styloko',
				backgroundImage: '/images/portfolios/styloko.jpg',
				lightbox: {
					featuredImage: '/images/portfolios/styloko.jpg',
					content:
						'<p> Styloko had an innovative idea: based on the concept of social and discovery commerce, users can follow brands, other style-setters, and curate their own shopping space. We built a web and mobile platform that offers price and product tracking capabilities to enable users to receive sale alerts when items go on sale or back in stock.</p>',
				},
			},

			{
				title: 'Mind Over Media',
				backgroundImage: '/images/portfolios/MoM.jpg',
				lightbox: {
					featuredImage: '/images/portfolios/MoM.jpg',
					content:
						'<p> Mind Over Media provides brand and marketing services. Image is everything, so it was vital to them that their development partner be able to deliver exceptional results. We turned their vision into an elegant, functional, and mobile-friendly website and client portal.</p>',
				},
			},

			{
				title: 'Global Help Swap',
				backgroundImage: '/images/portfolios/global.jpg',
				lightbox: {
					featuredImage: '/images/portfolios/global.jpg',
					content:
						'<p>Global Help Swap was one of those projects we get passionate about - a charitable foundation focused on volunteerism. They had an idea to allow volunteers to better connect with volunteer opportunities. We built them a social platform with a streamlined registration process that quickly gets volunteers in front of opportunities.</p>',
				},
			},

			{
				title: 'Grandmas Jars',
				backgroundImage: '/images/portfolios/grandma.jpg',
				lightbox: {
					featuredImage: '/images/portfolios/grandma.jpg',
					content:
						'<p>Grandmas Jars provide personal finance and budget coaching and tools. They came to us when they were ready to move from excel spreadsheets to the world wide web. We designed and built a marketing site, a web application, and a companion mobile application for personal budgeting.</p>',
				},
			},

			{
				title: 'Noonsite',
				backgroundImage: '/images/portfolios/noon.jpg',
				lightbox: {
					featuredImage: '/images/portfolios/noon.jpg',
					content:
						'<p>Noonsite is the oldest community of sailors online. With over 50,000 pages of content, they are a massive trove of vital information sailors reply on. They came to us with an outdated site and wanted to bring it into the future. We designed a membership site with various member-only features, a custom ad management platform, and special member-only offline access functionality.</p>' +
						'<p>They also needed someone to manage membership growth and ad sales. After we did our work their membership and newsletter subscriber numbers had grown from 2,500 to 27,000 and both member subscription revenues and ad revenues had grown substantially.</p>',
				},
			},

			{
				title: 'Preymaker',
				backgroundImage: '/images/portfolios/preymaker.jpg',
				lightbox: {
					featuredImage: '/images/portfolios/preymaker.jpg',
					content:
						'<p>Preymaker provides high-end videography services to top brands like Apple, Disney, and more. They came to us when the pandemic changed the way people work. From on-site presentations, they needed to migrate to a purely web-based solution for client presentations. We built a client portal that allowed them to present videos to clients, and importantly allow clients to request edits and watch them made in real-time.</p>',
				},
			},
		],
		itemsX: [
			{
				title: 'Sailors Hub',
				backgroundImage: '/images/portfolios/item_1.jpg',
				lightbox: {
					featuredImage: '/images/portfolios/item_1.jpg',
					content:
						'<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque, a nostrum. Dolorem, repellat quidem ut, minima sint vel eveniet quibusdam voluptates delectus doloremque, explicabo tempore dicta adipisci fugit amet dignissimos? </p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur sit commodi beatae optio voluptatum sed eius cumque, delectus saepe repudiandae explicabo nemo nam libero ad, doloribus, voluptas rem alias. Vitae?</p>' +
						'<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque, a nostrum. Dolorem, repellat quidem ut, minima sint vel eveniet quibusdam voluptates delectus doloremque, explicabo tempore dicta adipisci fugit amet dignissimos? </p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur sit commodi beatae optio voluptatum sed eius cumque, delectus saepe repudiandae explicabo nemo nam libero ad, doloribus, voluptas rem alias. Vitae?</p>' +
						'<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque, a nostrum. Dolorem, repellat quidem ut, minima sint vel eveniet quibusdam voluptates delectus doloremque, explicabo tempore dicta adipisci fugit amet dignissimos? </p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur sit commodi beatae optio voluptatum sed eius cumque, delectus saepe repudiandae explicabo nemo nam libero ad, doloribus, voluptas rem alias. Vitae?</p>' +
						'<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque, a nostrum. Dolorem, repellat quidem ut, minima sint vel eveniet quibusdam voluptates delectus doloremque, explicabo tempore dicta adipisci fugit amet dignissimos? </p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur sit commodi beatae optio voluptatum sed eius cumque, delectus saepe repudiandae explicabo nemo nam libero ad, doloribus, voluptas rem alias. Vitae?</p>' +
						'<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque, a nostrum. Dolorem, repellat quidem ut, minima sint vel eveniet quibusdam voluptates delectus doloremque, explicabo tempore dicta adipisci fugit amet dignissimos? </p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur sit commodi beatae optio voluptatum sed eius cumque, delectus saepe repudiandae explicabo nemo nam libero ad, doloribus, voluptas rem alias. Vitae?</p>',
				},
			},
			{
				title: 'Matieneo Marketing',
				backgroundImage: '/images/portfolios/item_2.jpg',
				lightbox: {
					featuredImage: '/images/portfolios/item_2.jpg',
					content:
						'<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque, a nostrum. Dolorem, repellat quidem ut, minima sint vel eveniet quibusdam voluptates delectus doloremque, explicabo tempore dicta adipisci fugit amet dignissimos? </p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur sit commodi beatae optio voluptatum sed eius cumque, delectus saepe repudiandae explicabo nemo nam libero ad, doloribus, voluptas rem alias. Vitae?</p>',
				},
			},
			{
				title: 'Guztavo Law Office',
				backgroundImage: '/images/portfolios/item_3.jpg',
				lightbox: {
					featuredImage: '/images/portfolios/item_3.jpg',
					content:
						'<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque, a nostrum. Dolorem, repellat quidem ut, minima sint vel eveniet quibusdam voluptates delectus doloremque, explicabo tempore dicta adipisci fugit amet dignissimos? </p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur sit commodi beatae optio voluptatum sed eius cumque, delectus saepe repudiandae explicabo nemo nam libero ad, doloribus, voluptas rem alias. Vitae?</p>',
				},
			},
			{
				title: 'AirBnB',
				backgroundImage: '/images/portfolios/item_4.jpg',
				lightbox: {
					featuredImage: '/images/portfolios/item_4.jpg',
					content:
						'<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque, a nostrum. Dolorem, repellat quidem ut, minima sint vel eveniet quibusdam voluptates delectus doloremque, explicabo tempore dicta adipisci fugit amet dignissimos? </p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur sit commodi beatae optio voluptatum sed eius cumque, delectus saepe repudiandae explicabo nemo nam libero ad, doloribus, voluptas rem alias. Vitae?</p>',
				},
			},
			{
				title: 'Nike',
				backgroundImage: '/images/portfolios/item_5.jpg',
				lightbox: {
					featuredImage: '/images/portfolios/item_5.jpg',
					content:
						'<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque, a nostrum. Dolorem, repellat quidem ut, minima sint vel eveniet quibusdam voluptates delectus doloremque, explicabo tempore dicta adipisci fugit amet dignissimos? </p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur sit commodi beatae optio voluptatum sed eius cumque, delectus saepe repudiandae explicabo nemo nam libero ad, doloribus, voluptas rem alias. Vitae?</p>',
				},
			},
			{
				title: 'Addidas',
				backgroundImage: '/images/portfolios/item_6.jpg',
				lightbox: {
					featuredImage: '/images/portfolios/item_6.jpg',
					content:
						'<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque, a nostrum. Dolorem, repellat quidem ut, minima sint vel eveniet quibusdam voluptates delectus doloremque, explicabo tempore dicta adipisci fugit amet dignissimos? </p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur sit commodi beatae optio voluptatum sed eius cumque, delectus saepe repudiandae explicabo nemo nam libero ad, doloribus, voluptas rem alias. Vitae?</p>',
				},
			},
		],
		templateX: (collections) => {
			if ('object' !== typeof collections) return

			const _validate = {
				title: (_title) => {
					if ('string' === typeof _title) {
						return (
							<h4 className="title">
								<Link to="#">{_title}</Link>
							</h4>
						)
					}
				},
			}

			const renderItems = (items) => {
				if ('object' !== typeof items) return

				return items.map(({ title, backgroundImage }, index) => (
					<div
						key={index}
						className="item"
						style={{
							backgroundImage: `url(${backgroundImage})`,
						}}
					>
						{_validate.title(title)}
					</div>
				))
			}

			return collections.map((collection, index) => (
				<div key={index} className="content-row">
					{renderItems(collection)}
				</div>
			))
		},
	}

	useEffect(() => {
		appDispatch({ type: 'setPageTitle', value: 'Home' })
	}, [])

	return (
		<SiteWrapper
			className="homepage"
			state={appState}
			dispatch={appDispatch}
			headerGutter={false}
			headerWide={true}
		>
			<Section id="site-banner" className="site-banner" wide={true}>
				<div className="inner-section container">
					<div className="row">
						<div className="caption-col col">
							<div className="caption-wrap">
								{/* <h3 className="sub-caption">
									How can we help you
								</h3> */}
								<h2 className="caption">
									We make your ideas look great
								</h2>
								<a
									href="#portfolios-section"
									className="gp-btn btn-rounded button"
								>
									<i className="fa fa-search"></i>
									Our Work
								</a>
							</div>
						</div>
						<div className="carousel-col col">
							<CircularCarousel config={carouselConfig} />
						</div>
					</div>
				</div>
			</Section>

			<Section
				id="testimonials-section"
				className="site-testimonials section-margin__bottom__small"
				wide={true}
			>
				<h2 className="section-title">Customer Stories</h2>

				<SlickCarousel config={testimonialsCarouselConfig} />
			</Section>

			<Section
				id="portfolios-section"
				className="site-portfolios section-padding__bottom__small"
				wide={true}
			>
				<h2 className="section-title">Portfolio</h2>

				<Gridz config={portfoliosGridzConfig} />
			</Section>
		</SiteWrapper>
	)
}

export default Home
