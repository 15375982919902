import React, { Component, useContext } from 'react'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'

import { StateContext, DispatchContext } from '../containers'
import { Home, Portfolios } from '../pages'

export class Main extends Component {
	constructor(props) {
		super(props)
	}

	render() {
		const state = this.props.state
		const dispatch = this.props.dispatch

		return (
			<StateContext.Provider value={state}>
				<DispatchContext.Provider value={dispatch}>
					{/* <Router refx={this.currentRef}> */}
					<Router>
						<Routes>
							<Route path="/" exact element={<Home />} />
							<Route
								path="/portfolios"
								exact
								element={<Portfolios />}
							/>
						</Routes>
					</Router>
				</DispatchContext.Provider>
			</StateContext.Provider>
		)
	}
}

export default Main
