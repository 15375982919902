import React, { useRef, useEffect } from 'react'

function Footer(props) {
	const ref = useRef('footer')

	return (
		<footer id="site-footer" className="site-footer">
			<div id="copyright" className="copyright">
				© Copyright 2022 | GrowPowerSupport
			</div>
		</footer>
	)
}

export default Footer
