import React, { Component } from 'react'

import { Link } from 'react-router-dom'

import JsxParser from 'react-jsx-parser'

import { v4 as uuidv4 } from 'uuid'

import Popup from 'reactjs-popup'

export class Gridz extends Component {
	/**
	 * The base prefix className.
	 * @val {String}
	 */
	componentClassName = 'gridz'

	/**
	 * The className used by the root element wrapper element of this component.
	 * @val {String}
	 */
	componentWrapperClassName = `${this.componentClassName}-component-wrapper`

	/**
	 * Used to provide a unique ID's for component rendered element.
	 * @val {String}
	 */
	uniqueID = `${this.componentClassName}-component-${uuidv4()}`

	/**
	 * The collections of element selectors.
	 * @val {Object}
	 */
	elSelectors = {
		LightboxBodyActiveCls: `${this.componentClassName}-lightbox__active`,
	}
	/**
	 * Used as referer if a component is mounted or not.
	 * @val {Boolean}
	 */
	isMounted = false

	/**
	 * Used as referer for handling timeout.
	 * @val {Object}
	 */
	timeout = {
		handler: null,
		interval: 1000,
		resetInterval: 30,
	}

	/**
	 * The property used to by methods to handle the processes.
	 * @val {Object}
	 */
	config = {
		className: '',
		contentPosition: 'positioned__center',
		debug: false,
		isTemplateValid: true,
		hasCustomTemplate: false,
		alternateReverseRow: false,
		template: null,
	}

	/**
	 * The property used for mobile responsive capability.
	 * @val {Array}
	 */
	breakpoints = [
		{
			breakpoint: 992,
			columns: 3,
		},
		{
			breakpoint: 768,
			columns: 2,
		},
		{
			breakpoint: 480,
			columns: 1,
		},
	]

	/**
	 * The default state properties
	 * @val {Object}
	 */
	state = {
		// Private States
		currentBreakpoint: 0,

		// Prop States
		items: [],
		collections: [],
		columns: 3,
		hasLightbox: true,
	}

	/**
	 * The class constructor method which initialize the whole process.
	 *
	 * @param {Object} props The provided component properties and configurations.
	 * @returns {Void}
	 */
	constructor(props) {
		super(props)
		this.elementRef = React.createRef()
		this.props = props
		this.config.className = `${this.componentWrapperClassName}`

		// Pass the {props.config} which contains the assigned configurations
		this.setProperties(this.props.config, true)
	}

	/**
	 * Sets and validates the component configurations.
	 *
	 * @param {Object}  config 		The provided component configurations to be validated.
	 * @param {Boolean} executeFunc Wheater to execute any functions during the process.
	 * @returns {Void}
	 */
	setProperties(config, executeFunc = false) {
		// If config is object continue the process
		if ('object' === typeof config) {
			/**
			 * Validation the settings for "config property."
			 */
			if (config.className) {
				this.config.className += ` ${config.className}`
			}

			// Validate and set Debug Mode
			if ('boolean' === typeof config.debug) {
				this.config.debug = config.debug
				this.config.className += ' debug-active'
			}

			// Validate and set Reset Timeout Interval
			if ('object' === typeof config.timeout) {
				if ('number' === typeof config.timeout.interval) {
					this.timeout.interval = config.timeout.interval
				}
				if ('number' === typeof config.timeout.resetInterval) {
					this.timeout.resetInterval = config.timeout.resetInterval
				}
			}

			// Validate and set Debug Mode
			if ('boolean' === typeof config.alternateReverseRow) {
				this.config.alternateReverseRow = config.alternateReverseRow
				this.config.className += ' rows-alternate__reverse'
			}

			// Responsive Breakpoints
			if (Array.isArray(config.breakpoints)) {
				// Create a mutable copy of {breakpoints} object property
				let breakpoints = config.breakpoints

				// Count total items of {breakpoints} object
				let responsiveItems = breakpoints.length

				// Create a mutable copy of {this.props.config}
				let baseConfig = this.props.config

				// Set {baseConfig} breakpoint to {9999}
				baseConfig['breakpoint'] = 9999

				// Remove the breakpoints property in {baseConfig} object
				// delete baseConfig.breakpoints

				// Add {baseConfig} in {breakpoints} object
				breakpoints[responsiveItems] = baseConfig

				// Set {this.breakpoints} value to {breakpoints} mutable copy
				this.breakpoints = breakpoints

				// Execute function calls here
				if (executeFunc) {
				}
			}

			// Checks if config has custom template function.
			if (
				'function' ===
				typeof this.getPropExists(config, 'template', null)
			) {
				this.config.hasCustomTemplate = true
				this.config.template = config.template
			}

			// Assign the class for the contentPosition property
			if (this.checkProp(config, 'contentPosition')) {
				switch (config.contentPosition) {
					case 'left':
						this.config.contentPosition = 'positioned__left'
						break
					case 'right':
						this.config.contentPosition = 'positioned__right'
						break
					default:
					case 'center':
						this.config.contentPosition = 'positioned__center'
						break
				}
			} else {
				this.config.contentPosition = 'positioned__center'
			}

			/**
			 * Validation the settings for "config property."
			 */
			this.setStateProps(config)
		} else {
			this.execErrorMessage(
				`The "setProperties" method requires first parameter to be an "object".`
			)
		}
	}

	/**
	 * Sets and validates the component states.
	 *
	 * @param {Object}  config 		The provided component states to be validated.
	 * @returns {Void}
	 */
	setStateProps(config) {
		// If config is object continue the process
		if ('object' === typeof config) {
			// Validate and set State Items
			if (Array.isArray(config.items)) {
				if (config.items.length !== 0) {
					this.state.items = config.items
				}
			}

			// Check if Lightbox is enabled.
			if (this.checkProp(config, 'hasLightbox')) {
				if ('boolean' === typeof config.hasLightbox) {
					this.state.hasLightbox = config.hasLightbox
				}
			}

			// Assign the class for the column property
			if (this.checkProp(config, 'columns')) {
				switch (config.columns) {
					case 1:
						this.state.columns = 1
						break
					case 2:
						this.state.columns = 2
						break
					case 3:
						this.state.columns = 3
						break
					case 4:
						this.state.columns = 4
						break
					case 5:
						this.state.columns = 5
						break
					case 6:
						this.state.columns = 6
						break
					default:
						this.state.columns = 3
						break
				}
			}
		} else {
			this.execErrorMessage(
				`The "setStateProps" method requires first parameter to be an "object".`
			)
		}
	}

	filterItemsByRows(items, reset = false) {
		if (reset) {
			this.setState((state) => ({
				collections: [],
			}))
		}

		// let newCollections = []

		const populateRows = (rows) => {
			this.state.collections.push(rows)
			// newCollections.push(rows)
			// this.setState((state) => ({
			// 	collections: newCollections,
			// }))
		}

		const { columns } = this.state

		let rows = [] // the temporary array

		items.forEach((item) => {
			rows.push(item) // add card to temp array
			if (rows.length === columns) {
				populateRows(rows)
				rows = [] // reset the temp array
			}
		})

		// there may still be item available
		if (rows.length) {
			populateRows(rows)
		}
	}

	/**
	 * Invokes immediately after a component is mounted (inserted into the tree).
	 * Initialization that requires DOM nodes should go here.
	 * If you need to load data from a remote endpoint, this is a good place to instantiate the network request.
	 */
	componentDidMount() {
		this.isMounted = true

		this.filterItemsByRows(this.state.items)

		// Apply the assigned configs base on device width
		this.execResponsive()

		// Apply the assigned configs onLoad
		this.execOnLoad()

		// Apply the assigned configs onResize
		this.execOnResize()
	}

	/**
	 * Invokes immediately before a component is unmounted and destroyed.
	 * Perform any necessary cleanup in this method, such as invalidating timers, canceling network requests,
	 * or cleaning up any subscriptions that were created in componentDidMount().
	 */
	componentWillUnmount() {
		this.isMounted = false
	}

	/**
	 * Invokes immediately after updating occurs.
	 * This method is not called for the initial render.
	 */
	componentDidUpdate() {}

	/**
	 * Checks if element has an assigned React Ref.
	 *
	 * @returns {String} 	Returns true, if element has an assigned React Ref. Otherwise, return false.
	 */
	componentHasRef() {
		return this.elementRef.current != null
	}

	/**
	 * Execute new Error Exeption Message
	 *
	 * @param {String} message	The message to display
	 * @throws {Error} Error	Throws new Error exeption
	 * @returns {void} 			Returns void
	 */
	execErrorMessage(message) {
		if ('string' !== typeof message) {
			throw new Error(message)
		}
	}

	/**
	 * Check if the data-type is Function
	 *
	 * @param 	{Function} data The value to check
	 * @returns {Boolean} 		Returns true if it is Function. Otherwise, false.
	 */
	isFunction(data) {
		if (typeof data === 'function') return true
		else return false
	}

	/**
	 * Check if the data-type is Array
	 *
	 * @param 	{Array} data 	The value to check
	 * @returns {Boolean} 		Returns true if it is Array. Otherwise, false.
	 */
	isArray(data) {
		if (Array.isArray(data)) return true
		else return false
	}

	/**
	 * Check if the data-type is Object
	 *
	 * @param 	{Object} data 	The value to check
	 * @returns {Boolean} 		Returns true if it is Object. Otherwise, false.
	 */
	isObject(data) {
		if (typeof data === 'object') return true
		else return false
	}

	/**
	 * Check if the data-type is Boolean
	 *
	 * @param 	{Boolean} data 	The value to check
	 * @returns {Boolean} 		Returns true if it is Boolean. Otherwise, false.
	 */
	isBool(data) {
		if (typeof data === 'boolean') return true
		else return false
	}

	/**
	 * Check if the data-type is Integer
	 *
	 * @param 	{Integer} data 	The value to check
	 * @returns {Boolean} 		Returns true if it is Integer. Otherwise, false.
	 */
	isInt(data) {
		if (typeof data === 'number') return true
		else return false
	}

	/**
	 * Check if the data-type is String
	 *
	 * @param 	{String} data 	The value to check
	 * @returns {Boolean} 		Returns true if it is String. Otherwise, false.
	 */
	isString(data) {
		if (typeof data === 'string') return true
		else return false
	}

	/**
	 * Check if the value of data is set to function, array, object, boolean, number, string and not equal to null.
	 *
	 * @param 	{any} data 	The value to check
	 * @returns {Boolean} 	Returns true if it is set to function, array, object, boolean, number, string and not equal to null. Otherwise, false.
	 */
	isSet(data) {
		if (
			data !== null ||
			Array.isArray(data) ||
			typeof data === 'function' ||
			typeof data === 'object' ||
			typeof data === 'boolean' ||
			typeof data === 'number' ||
			typeof data === 'string'
		)
			return true
		else return false
	}

	/**
	 * Check if the data is not empty
	 *
	 * @param 	{any} data 	The value to check
	 * @returns {Boolean} 		Returns true if the value is empty. Otherwise, false.
	 */
	isEmpty(data) {
		if (this.isSet(data) || data !== '') return true
		else return false
	}

	/**
	 * Checks a number if it is in range.
	 *
	 * @param {Integer} value 		The number to check if in range.
	 * @param {Integer} min 		The minimum range
	 * @param {Integer} max 		The maximum range
	 * @param {Boolean} returnValue Whether to return value or check if number is in range.
	 * @returns 					Returns range if returnValue is set to true. Otherwise, return boolean.
	 */
	intRange(value, min, max, returnValue = false) {
		// Calculate the number is within the inclusive min and max number
		let range = Math.min(Math.max(value, min), max)

		// Check if returnValue is boolean
		if ('boolean' !== typeof returnValue) returnValue = false

		// If range is NaN return
		if (isNaN(range)) return

		if (returnValue) {
			return range
		} else {
			min = parseInt(min)
			max = parseInt(max)

			return range >= min || range <= max ? true : false
		}
	}

	/**
	 * Finds nearest decending number value in every item of an Object with sub-object or Array with sub-object
	 *
	 * @param {Object|Array} data 	The Object with sub-object or Array with sub-object as items
	 * @param {String} key			The property name to be search in every sub-object of an {data}
	 * @param {Number} value		The value of the property to be search in every sub-object of an {data}
	 * @param {String} operator		The Operator to control the nearest number. Allowed {<} or {<=} sign
	 * @returns						Returns an object which is an item from {data}
	 */
	findClosestInt(data, key = '', value, operator = '<') {
		if ('string' !== typeof key || 'number' !== typeof value) return

		// By default that will be a big number
		let closestValue = Infinity

		// We will store the index of the element
		let closestIndex = -1

		// The allowed operators for the operator variable
		let operators = {
			'<=': (a, b) => {
				return a <= b
			},
			'< !': (a, b) => {
				return a < b
			},

			'<': (a, b) => {
				return a < b
			},
			'>': (a, b) => {
				return a > b
			},
		}

		if (Array.isArray(data)) {
			if (Array.prototype.map) {
				data.map((item, index) => {
					var diff = Math.abs(item[key] - value)
					if (operators[operator](diff, closestValue)) {
						closestValue = diff
						closestIndex = index
					}
				})
			} else {
				for (var index = 0; index < data.length; ++index) {
					var diff = Math.abs(data[index][key] - value)
					if (operators[operator](diff, closestValue)) {
						closestValue = diff
						closestIndex = index
					}
				}
			}

			return data[closestIndex]
		}

		if ('object' === typeof data) {
			for (const prop in data) {
				var diff = Math.abs(data[prop][key] - value)

				if (operators[operator](diff, closestValue)) {
					closestValue = diff
					closestIndex = prop
				}
			}

			return data[closestIndex]
		}
	}

	/**
	 * Wrapper function for Object.keys() which support backwards compatability.
	 *
	 * @param 	{Object}  object 		The object of which the enumerable's own properties are to be returned.
	 * @param 	{Boolean} maintainOrder Whether to maintain the original order of properties in the object.
	 * @returns {Object}  result		An object of strings that represent all the enumerable properties of the given object.
	 */
	objectKeys(object, maintainOrder = true) {
		if (typeof object !== 'object') return

		// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
		if (!Object.keys) {
			Object.keys = (function () {
				'use strict'

				var hasOwnProperty = Object.prototype.hasOwnProperty,
					hasDontEnumBug = !{ toString: null }.propertyIsEnumerable(
						'toString'
					),
					dontEnums = [
						'toString',
						'toLocaleString',
						'valueOf',
						'hasOwnProperty',
						'isPrototypeOf',
						'propertyIsEnumerable',
						'constructor',
					],
					dontEnumsLength = dontEnums.length

				return function (obj) {
					if (
						typeof obj !== 'function' &&
						(typeof obj !== 'object' || obj === null)
					) {
						throw new TypeError('Object.keys called on non-object')
					}

					var result = [],
						prop,
						i

					for (prop in obj) {
						if (hasOwnProperty.call(obj, prop)) {
							result.push(prop)
						}
					}

					if (hasDontEnumBug) {
						for (i = 0; i < dontEnumsLength; i++) {
							if (hasOwnProperty.call(obj, dontEnums[i])) {
								result.push(dontEnums[i])
							}
						}
					}

					if (maintainOrder) result.reverse()

					return result
				}
			})()
		} else {
			let result = Object.keys(object)

			if (maintainOrder) result.reverse()

			return result
		}
	}

	/**
	 * Returns a new object with the values at each key mapped using callback as a callback function.
	 *
	 * @param 	{Object} object		The Object to be map
	 * @param 	{Function} callback	The function to be used as callback
	 * @returns {Object} result		Returns a new object with the values at each key mapped.
	 */
	objectMap(object, callback) {
		return this.objectKeys(object).reduce((result, key) => {
			result[key] = callback(object, key)
			// result[key] = callback(object[key], key)
			return result
		}, {})
	}

	/**
	 * Fetch object property value using property name. Otherwise, set fallback value if it does not exists.
	 *
	 * @param {Object}  object   The object to examine
	 * @param {String}  name 	 The property name
	 * @param {any} 	fallback The fallback value
	 *
	 * @returns {any}	Returns property value if property name exists. Otherwise, return the fallback value
	 */
	getPropExists(object = {}, name = '', fallback = '') {
		if (
			!this.isObject(object) &&
			!this.isEmpty(name) &&
			!this.isEmpty(fallback)
		)
			return

		if (object.hasOwnProperty(name)) {
			return object[name]
		} else {
			return fallback
		}
	}

	/**
	 * Fetch object property or nested property using ES6.
	 *
	 * @usage this.getProp({prop1:{prop2:{prop3:'propVal'}} }, 'prop1', 'prop2', 'prop3')
	 *
	 * @shorthand {2019-10-17} allow you to safely access deeply nested properties, by using the token '?.',
	 * 			  the new optional chaining operator:
	 *
	 * 			  Fetch property: obj?.prop1?.prop2?.prop3
	 * 			  Method call: obj?.level1?.method_name();
	 *
	 * @param {Object} obj 			The Object where to fetch the property
	 * @param  {...String} args 	The nested propery names to access using JS 'spread or rest operator'
	 *
	 * @returns {any}		The object property value
	 */
	getProp(obj, ...args) {
		return args.reduce((obj, level) => obj && obj[level], obj)
	}

	/**
	 * Check if an object property or nested property exist.
	 *
	 * @usage this.checkProp({prop1:{prop2:{prop3:'propVal'}} }, 'prop1', 'prop2', 'prop3')
	 *
	 * @param {Object} obj 			The Object where to fetch the property
	 * @param  {...String} level 	The name of direct child propery to access.
	 * @param  {...String} rest 	The nested propery names to access using JS 'spread or rest operator'
	 *
	 * @returns {Boolean}			Returns true if property exist. Otherwise, false.
	 */
	checkProp(obj, level, ...rest) {
		if (obj === undefined) return false
		if (rest.length == 0 && obj.hasOwnProperty(level)) return true
		return this.checkProp(obj[level], ...rest)
	}

	/**
	 * Returns a new array with the values at each key mapped using callback as a callback function.
	 * Wrapper function for Array.prototype.map()
	 *
	 * @param 	{Array} 	data		The array to be map
	 * @param 	{Function}  callback	The function to be used as callback
	 * @link	https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/map
	 * @returns {Array}		result		Returns a new array with the values at each key mapped.
	 */
	arrayMap(data, callback) {
		// Production steps of ECMA-262, Edition 5, 15.4.4.19
		// Reference: https://es5.github.io/#x15.4.4.19
		if (!Array.prototype.map) {
			Array.prototype.map = function (callback /*, thisArg*/) {
				var T, A, k

				if (this == null) {
					throw new TypeError('this is null or not defined')
				}

				// 1. Let O be the result of calling ToObject passing the |this|
				//    value as the argument.
				var O = Object(this)

				// 2. Let lenValue be the result of calling the Get internal
				//    method of O with the argument "length".
				// 3. Let len be ToUint32(lenValue).
				var len = O.length >>> 0

				// 4. If IsCallable(callback) is false, throw a TypeError exception.
				// See: https://es5.github.com/#x9.11
				if (typeof callback !== 'function') {
					throw new TypeError(callback + ' is not a function')
				}

				// 5. If thisArg was supplied, let T be thisArg; else let T be undefined.
				if (arguments.length > 1) {
					T = arguments[1]
				}

				// 6. Let A be a new array created as if by the expression new Array(len)
				//    where Array is the standard built-in constructor with that name and
				//    len is the value of len.
				A = new Array(len)

				// 7. Let k be 0
				k = 0

				// 8. Repeat, while k < len
				while (k < len) {
					var kValue, mappedValue

					// a. Let Pk be ToString(k).
					//   This is implicit for LHS operands of the in operator
					// b. Let kPresent be the result of calling the HasProperty internal
					//    method of O with argument Pk.
					//   This step can be combined with c
					// c. If kPresent is true, then
					if (k in O) {
						// i. Let kValue be the result of calling the Get internal
						//    method of O with argument Pk.
						kValue = O[k]

						// ii. Let mappedValue be the result of calling the Call internal
						//     method of callback with T as the this value and argument
						//     list containing kValue, k, and O.
						mappedValue = callback.call(T, kValue, k, O)

						// iii. Call the DefineOwnProperty internal method of A with arguments
						// Pk, Property Descriptor
						// { Value: mappedValue,
						//   Writable: true,
						//   Enumerable: true,
						//   Configurable: true },
						// and false.

						// In browsers that support Object.defineProperty, use the following:
						// Object.defineProperty(A, k, {
						//   value: mappedValue,
						//   writable: true,
						//   enumerable: true,
						//   configurable: true
						// });

						// For best browser support, use the following:
						A[k] = mappedValue
					}
					// d. Increase k by 1.
					k++
				}

				// 9. return A
				return A
			}
		}

		return data.map((result, index) => {
			result[index] = callback(data, index)
			// result[index] = callback(object[index], index)
			return result
		})
	}

	/**
	 * Merge arrays or objects
	 *
	 * @param 	{Array|Object} 	arguments The array or object to be map
	 *
	 * @demo var $arr1 = {"color": "red", 0: 2, 1: 4}
	 *       var $arr2 = {0: "a", 1: "b", "color": "green", "shape": "trapezoid", 2: 4}
	 *       this.arrayMerge($arr1, $arr2)
	 *       returns: {"color": "green", 0: 2, 1: 4, 2: "a", 3: "b", "shape": "trapezoid", 4: 4}
	 *
	 * @demo var $arr1 = []
	 *       var $arr2 = {1: "data"}
	 *       pmt_array_merge($arr1, $arr2)
	 *       returns: {0: "data"}
	 */
	arrayMerge(...datas) {
		const args = Array.prototype.slice.call(datas)
		const argl = args.length
		let arg
		const retObj = {}
		let k = ''
		let argil = 0
		let j = 0
		let i = 0
		let ct = 0
		const toStr = Object.prototype.toString
		let retArr = true

		// loop the argl
		for (i = 0; i < argl; i++) {
			// check if args is not '[object Array]'
			if (toStr.call(args[i]) !== '[object Array]') {
				retArr = false
				break
			}
		}

		// If retArr is true
		if (retArr) {
			// Set value to empty array
			retArr = []

			// Loop the argl and contatinate the args value to retArr
			for (i = 0; i < argl; i++) {
				retArr = retArr.concat(args[i])
			}
			return retArr
		}

		for (i = 0, ct = 0; i < argl; i++) {
			arg = args[i]

			if (toStr.call(arg) === '[object Array]') {
				for (j = 0, argil = arg.length; j < argil; j++) {
					retObj[ct++] = arg[j]
				}
			} else {
				for (k in arg) {
					if (arg.hasOwnProperty(k)) {
						if (parseInt(k, 10) + '' === k) {
							retObj[ct++] = arg[k]
						} else {
							retObj[k] = arg[k]
						}
					}
				}
			}
		}

		return retObj
	}

	/**
	 * Checks if a URL is a valid URL.
	 *
	 * @param {String} url 				The URL to validate.
	 * @param {Boolean} allowElementID 	Wether to allow Element ID.
	 * @returns 						Returns true if it is a valid URL. Otherwise, false.
	 */
	isValidURL(url, allowElementID = true) {
		if (typeof url == 'undefined') {
			url = ''
		}

		let regex =
			/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
		let res = url.match(regex)

		// Check if it has a hash element ID
		if (allowElementID && url.indexOf('#') != -1) {
			res = true
		}

		return res !== null
	}

	/**
	 * Execute Load Event Listener
	 * @param {function} 		callback  The function to execute
	 * @param {String}   		eventType A case-sensitive string representing the event type to listen for.
	 * @param {Integer|String}  refresh   The setTimeout() refresh time. If set to 'none' no setTimeout() to use.
	 * @param {String}   		option	  An object that specifies characteristics about the event listener.
	 * @see   https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
	 */
	eventHandler = (callback, eventType, refresh = 50, option = false) => {
		// Make sure a valid callback was provided
		if (!callback || typeof callback !== 'function') return

		if ('' === callback || typeof eventType !== 'string') return

		// Setup eventHandler variable
		let eventHandler

		// Listen for event
		window.addEventListener(
			eventType,
			function (e) {
				// Clear our timeout throughout the event
				window.clearTimeout(eventHandler)

				if ('none' === refresh) {
					eventHandler = callback()
				} else {
					// Set a timeout to run after event ends
					eventHandler = setTimeout(callback, refresh)
				}
			},
			option
		)
	}

	/**
	 * Execute Load Event Listener
	 * @param {function} 		callback  The function to execute
	 * @param {Integer|String}  refresh   The setTimeout() refresh time. If set to 'none' no setTimeout() to use.
	 * @param {String}   		option	  An object that specifies characteristics about the event listener.
	 * @see   this.eventHandler()
	 */
	onLoad = (callback, refresh = 50, option = false) => {
		this.eventHandler(callback, 'load', refresh, option)
	}

	/**
	 * Execute Scroll Event Listener
	 * @param {function} 		callback  The function to execute
	 * @param {Integer|String}  refresh   The setTimeout() refresh time. If set to 'none' no setTimeout() to use.
	 * @param {String}   		option	  An object that specifies characteristics about the event listener.
	 * @see   this.eventHandler()
	 */
	onScroll = (callback, refresh = 50, option = false) => {
		this.eventHandler(callback, 'scroll', refresh, option)
	}

	/**
	 * Execute Resize Event Listener
	 * @param {function} 		callback  The function to execute
	 * @param {Integer|String}  refresh   The setTimeout() refresh time. If set to 'none' no setTimeout() to use.
	 * @param {String}   		option	  An object that specifies characteristics about the event listener.
	 * @see   this.eventHandler()
	 */
	onResize = (callback, refresh = 50, option = false) => {
		this.eventHandler(callback, 'resize', refresh, option)
	}

	/**
	 * Executes a timeout handler
	 *
	 * @param {Function} callback	The function to execute during the timeout.
	 * @param {Number} 	 timeout 	The millisecond for the timeout.
	 * @returns
	 */
	execTimeout = (callback, timeout = 1000) => {
		// Make sure a valid callback was provided
		if (!callback || typeof callback !== 'function') return

		if ('' === callback) return

		// Set a timeout to run after event ends
		// this.timeoutHandler = setTimeout(callback, timeout)
		this.timeoutHandler = setTimeout(
			function () {
				callback()
			}.bind(this),
			timeout
		)

		// If debug is enabled
		if (this.config.debug) {
			console.log(`Timeout Interval: ${this.timeoutHandler}`)
		}

		if (this.timeout.resetInterval <= this.timeout.handler) {
			clearTimeout(this.timeout.handler)
		}
	}

	/**
	 * Use this method to execute breakpoints configurations.
	 */
	execResponsive = (isResizing = false) => {
		// Create a mutable copy of {breakpoints} object property
		let breakpoints = this.breakpoints
		let updatedBreakpoint = 0

		if (breakpoints.length !== 0) {
			let configs = {},
				deviceHeight = window.innerHeight,
				deviceWidth = window.innerWidth

			configs = this.findClosestInt(
				breakpoints,
				'breakpoint',
				deviceWidth,
				'<'
			)

			if (this.checkProp(configs, 'breakpoint')) {
				updatedBreakpoint = this.getProp(configs, 'breakpoint')
			}

			// Execute if {configs} does not have 'items' property
			if (!this.checkProp(configs, 'items')) {
				configs = Object.assign(
					{
						items: this.props.config.items,
					},
					configs
				)
			}

			// Re-render the properties
			this.setProperties(configs)

			// Execute process onResize
			if (isResizing) {
				this.filterItemsByRows(this.state.items, true)
			}

			/**
			 * Execute an empty {this.setState()} call to apply the changes from {this.setProperties()}
			 */
			this.setState((state) => ({
				currentBreakpoint:
					'undefined' === updatedBreakpoint ? 0 : updatedBreakpoint,
			}))
		}
	}

	/**
	 * Use this method to execute the {this.execResponsive()} onResize.
	 */
	execOnResize = () => {
		// Create a mutable copy of {breakpoints} object property
		let breakpoints = this.breakpoints

		// Check if {breakpoints} has items
		if (breakpoints.length !== 0) {
			// Execute resize event handler
			this.onResize(() => {
				this.execResponsive(true)
			}, '500')
		}
	}

	/**
	 * Use this method to execute the {this.execResponsive()} onLoad.
	 */
	execOnLoad = () => {
		this.onLoad(() => {
			this.execResponsive(true)
		}, '50')
	}

	/**
	 * Display a console.log() or console.table() if this.config.debug is enabled
	 *
	 * @param 	{Mixed}		content Any datatype as content.
	 * @param 	{String}	type 	Use as filter wheather to display as console.log() or console.table()
	 * @returns {Void}
	 */
	log(content = '', type = 'log') {
		if (this.config.debug) {
			switch (type) {
				case 'table':
					if (this.isObject(content)) {
						console.table(content)
					}
					break

				case 'log':
				default:
					console.log(content)
					break
			}
		}
		return
	}

	lightboxTemplate(args = {}) {
		if (typeof args !== 'object') {
			throw new Error('Parameter "args" must be an object.')
		}
		let { hasLightbox } = this.state

		let default_args = {
			_key: '',
			_trigger: '',
			_title: '',
			_content: '',
		}

		args = Object.assign(default_args, args)

		let { _key, _trigger, _title, _content } = args

		if (hasLightbox) {
			return (
				<Popup
					className={`${this.componentClassName}-lightbox__wrapper gp-lightbox__wrapper`}
					key={_key}
					trigger={_trigger}
					modal
					onOpen={() => {
						document.body.classList.add(
							this.elSelectors.LightboxBodyActiveCls
						)
					}}
					onClose={() => {
						document.body.classList.remove(
							this.elSelectors.LightboxBodyActiveCls
						)
					}}
				>
					{(close) => (
						<div className="modal">
							<button className="close" onClick={close}>
								&times;
							</button>

							<div className="lightbox-inner__wrap">
								{_content}
							</div>
						</div>
					)}
				</Popup>
			)
		} else {
			return <></>
		}
	}

	/**
	 * The renders the default template
	 *
	 * @returns {Object}	The JSX markup for the items
	 */
	defaultTemplate() {
		// let items = this.getPropExists(this.state, 'items', [])
		let collections = this.getPropExists(this.state, 'collections', [])
		let hasLightbox = this.getPropExists(this.state, 'hasLightbox', false)

		const _validate = {
			title: (_title) => {
				if ('string' === typeof _title) {
					return <h4 className="title">{_title}</h4>
				}
			},
		}
		const _structure = {
			lightbox: (_lightbox) => {
				if ('object' === typeof _lightbox) {
					return (
						<div className="lightbox-inner__contents">
							{'' != _lightbox.featuredImage && (
								<div className="feat-image">
									<img src={_lightbox.featuredImage} />
								</div>
							)}
							{'' != _lightbox.title && (
								<h4 className="title">{_lightbox.title}</h4>
							)}
							<div className="content">
								<JsxParser jsx={_lightbox.content} />
							</div>
						</div>
					)
				}
			},
		}

		const renderItems = (items) => {
			if (!this.isArray(items)) return
			let lightboxArgs = {}
			let lightboxStructure = {}

			if (hasLightbox) {
				return items.map(
					({ title, backgroundImage, lightbox }, index) => {
						lightboxStructure = {
							title: title ? title : '',
							featuredImage: this.getProp(
								lightbox,
								'featuredImage'
							),
							content: this.getProp(lightbox, 'content'),
						}

						lightboxArgs = {
							_key: index,
							_trigger: (
								<div
									key={index}
									className="item"
									style={{
										backgroundImage: `url(${backgroundImage})`,
									}}
								>
									{_validate.title(title)}
								</div>
							),
							_title: _validate.title(title),
							_content: _structure.lightbox(lightboxStructure),
						}
						return this.lightboxTemplate(lightboxArgs)
					}
				)
			} else {
				return items.map(({ title, backgroundImage }, index) => (
					<div
						key={index}
						className="item"
						style={{
							backgroundImage: `url(${backgroundImage})`,
						}}
					>
						{_validate.title(title)}
					</div>
				))
			}
		}

		return collections.map((collection, index) => (
			<div key={index} className="content-row">
				{renderItems(collection)}
			</div>
		))
	}

	/**
	 * Checks the validity of the custom template
	 *
	 * @returns {Boolean}	Returns true if the provided configuration has a custom template.
	 */
	isCustomTemplateValid() {
		if ('function' !== typeof this.config.template) {
			this.config.isTemplateValid = false
		}

		let isValid = false
		const { hasCustomTemplate, isTemplateValid } = this.config

		if (hasCustomTemplate) {
			if (!isTemplateValid) {
				isValid = false
			} else {
				isValid = true
			}
		} else {
			isValid = false
		}

		if (!isValid) {
			this.execErrorMessage(
				`The "template" property only accepts "Function" as value.`
			)
		}
		return isValid
	}

	/**
	 * Decides wheater to render the default template or the provided custom template.
	 *
	 * @returns {Object}	The JSX markup for the items
	 */
	renderTemplate() {
		const { template } = this.config

		if (this.isCustomTemplateValid()) {
			let collections = this.getPropExists(this.state, 'collections', [])
			return template(collections)
		} else {
			return this.defaultTemplate()
		}
	}

	/**
	 * A method which is required method in a class component.
	 * Renders the markup for the whole component.
	 *
	 * @returns {Object}	The JSX markup for the component
	 */
	render() {
		return (
			<div
				ref={this.elementRef}
				id={this.uniqueID}
				className={this.config.className}
				data-breakpoint={this.state.currentBreakpoint}
				columns={this.state.columns}
			>
				<div className="inner-wrap">{this.renderTemplate()}</div>
			</div>
		)
	}
}

/**
 * Set default properties for Gridz()
 */
Gridz.defaultProps = {
	config: {
		className: '',
		contentPosition: 'positioned__center',
		debug: false,
		isTemplateValid: true,
		hasCustomTemplate: false,
	},
	state: {
		// Private States
		currentBreakpoint: 0,

		// Prop States
		items: [],
		collections: [],
		columns: 3,
		hasLightbox: true,
	},
}

export default Gridz
