import React, { useEffect, useRef } from 'react'

function Section(props) {
	const ref = useRef(props.ref ? props.ref : null)

	let id = props.id
		? props.id
		: `section-${Math.floor(Math.random() * 100000)}`
	let classNames = props.className ? ' ' + props.className : ''
	let fullWidth = props.wide ? ' container-fluid' : ' container'

	classNames = `section${classNames + fullWidth}`

	return (
		<section ref={ref} id={id} className={classNames}>
			{props.children}
		</section>
	)
}

export default Section
