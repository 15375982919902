import React, { useRef, useContext, useEffect } from 'react'

// React Context Component
import StateContext from '../context/StateContext'
import DispatchContext from '../context/DispatchContext'

// Section Components
import Header from '../header/Header'
import Footer from '../footer/Footer'

function SiteWrapper(props) {
	const appState = useContext(StateContext)
	const appDispatch = useContext(DispatchContext)

	const ref = useRef('main-wrapper')

	let headerGutter =
		appState.header.gutter == undefined ? true : appState.header.gutter
	let headerWide =
		appState.header.wide == undefined ? true : appState.header.wide
	let headerSticky =
		appState.header.sticky == undefined ? false : appState.header.sticky

	return (
		<div
			ref={ref}
			id="site-wrapper"
			className={`site-wrapper ${
				props.wide ? 'container' : 'container-fliud'
			} ${props.className ? props.className : ''}`}
		>
			<Header
				gutter={headerGutter}
				wide={headerWide}
				sticky={headerSticky}
			/>
			{props.children}
			<Footer />
		</div>
	)
}

export default SiteWrapper
