import React, { useEffect, useState, useContext, useRef } from 'react'
import { Link } from 'react-router-dom'

import { Menux, RequestQouteForm } from '../../components'

// React Context Component
import StateContext from '../context/StateContext'
import DispatchContext from '../context/DispatchContext'

function Header(props) {
	const ref = useRef('header')

	const appState = useContext(StateContext)
	const appDispatch = useContext(DispatchContext)

	const menus = {
		className: '',
		alignItems: 'top',
		justifyContent: 'right',
		mobileMenu: false,
		burgerMenu: false,
		burgerMenuType: 'style-3',
		items: [
			// {
			// 	classes: 'order-1',
			// 	label: 'Services',
			// 	title: 'Our Services',
			// 	url: '#services-section',
			// 	scrollTo: '#site-banner',
			// },
			{
				classes: 'order-2',
				label: 'Testimonials',
				title: 'Testimonials',
				url: '#testimonials-section',
				scrollTo: '#testimonials-section',
			},
			{
				classes: 'order-3',
				label: 'Portfolio',
				title: 'Portfolio',
				// url: '/portfolios',
				url: '#portfolios-section',
				scrollTo: '#portfolios-section',
			},
			{
				classes: 'order-4',
				label: 'Request a Quote',
				title: 'Request a Quote',
				lightbox: {
					title: 'Request a Quote',
					content: <RequestQouteForm />,
					parseContent: false,
				},
			},
		],
		breakpoints: [
			{
				breakpoint: 992,
				mobileMenu: true,
				burgerMenu: true,
			},
			{
				breakpoint: 993,
				mobileMenu: false,
				burgerMenu: false,
			},
		],
	}

	let id = props.id ? props.id : 'masthead'
	let isSticky = props.sticky ? ' sticky-header' : ''
	let hasGutter = props.gutter ? ' has-gutter' : ''
	let fullWidth = props.wide ? ' container-fluid' : ' container'
	let classNames = props.class ? ' ' + props.class : ''

	classNames = `header site-header gp-header${
		classNames + isSticky + hasGutter + fullWidth
	}`

	return (
		<header ref={ref} id={id} className={classNames} role="header">
			<div id="site-info" className="site-info screen-reader-text">
				<h1 className="site-title">
					<Link to="/">GrowPower</Link>{' '}
				</h1>
				<p id="site-description" className="site-description">
					How May We Support You Today?
				</p>
			</div>

			<div
				id="masthead-inner"
				className="masthead-inner container-fluid container-lg justify-content-center justify-content-md-start align-items-center d-flex flex-row align-content-around flex-wrap"
			>
				<div
					id="site-navigation-left"
					className="site-navigation-left site-branding left-column order-1 col-6 col-md-6 col-lg-3 d-flex justify-content-start pl-sm-0"
				>
					<Link to="/" title="Grow Power">
						<img
							id="site-logo"
							className="site-logo theme-logo"
							src="images/logo.png"
							alt="GrowPower Logo"
							width="200px"
							height="auto"
						/>
					</Link>
				</div>

				<div
					id="site-navigation-right"
					className="site-navigation-right right-column order-2 col-6 col-md-6 col-lg-9 d-flex justify-content-end pr-sm-0"
				>
					<Menux config={menus} />
				</div>
			</div>
		</header>
	)
}

export default Header
