// Import Helper functions
import Helpers from '../../helpers/Helpers'

export class StickyHeader {
	constructor() {
		this.site_wrapper = document.querySelector('#site-wrapper')
		this.site_header = document.querySelector('#masthead')
		this.site_banner = document.querySelector('#site-banner')
	}

	doesComponentsExists() {
		if (
			this.site_wrapper === null ||
			this.site_header === null ||
			this.site_banner === null
		) {
			return false
		}
		return true
	}

	init() {
		if (!this.doesComponentsExists()) return

		this.header_height =
			this.site_header !== null ? this.site_header.clientHeight : 0 // includes padding
		this.header_o_height =
			this.site_header !== null ? this.site_header.offsetHeight : 0 // includes padding

		this.setup()
		this.isScrolling()
	}

	setup() {
		if (!this.doesComponentsExists()) return

		let site_wrapper = this.site_wrapper
		let site_header = this.site_header
		let site_banner = this.site_banner
		let header_height = this.header_height

		Helpers.addClass(site_header, 'sticky-header')
		Helpers.addClass(site_header, 'sticky')

		if (0 !== header_height) {
			// site_wrapper.style.cssText = `padding-top: ${header_height}px;`
		}

		// console.log(Helpers.elemExists(site_banner))

		if (
			Helpers.hasClass(site_header, 'has-gutter') &&
			Helpers.elemExists(site_banner)
		) {
			// site_banner.style.cssText = `min-height: calc( 100vh - ${header_height}px);`
		}
	}

	isScrolling() {
		if (!this.doesComponentsExists()) return

		let site_wrapper = this.site_wrapper
		let site_header = this.site_header
		let site_banner = this.site_banner
		let header_height = this.header_height
		let hide_header = header_height + 100

		let _screen = window.scrollY
		let _offset = header_height

		if (_offset >= 50) {
			_offset = 50
		}

		// console.table({ _screen, header_height, hide_header })

		if (_screen <= header_height) {
			Helpers.addClass(site_wrapper, 'sticky-off')
			Helpers.removeClass(site_wrapper, 'sticky-on')
			Helpers.removeClass(site_header, 'sticky sticky-hidden')

			// site_header.style.cssText = `top: 0px;`
			if (Helpers.hasClass(site_header, 'has-gutter')) {
				site_wrapper.style.cssText = `padding-top: ${header_height}px;`
			} else {
				site_wrapper.style.cssText = `padding-top: 0px;`
				// site_banner.style.cssText = `min-height: 100vh;`
			}
		} else if (_screen <= hide_header) {
			Helpers.removeClass(site_wrapper, 'sticky-off')
			Helpers.addClass(site_wrapper, 'sticky-on')
			Helpers.addClass(site_header, 'sticky sticky-hidden')

			// site_header.style.cssText = `top: -${header_height}px;`
		} else if (_screen >= hide_header) {
			Helpers.removeClass(site_header, 'sticky-hidden')
			// site_header.style.cssText = `top: 0px;`
		} else {
			Helpers.addClass(site_wrapper, 'sticky-off')
			Helpers.removeClass(site_wrapper, 'sticky-on')
			Helpers.removeClass(site_header, 'sticky')
		}
	}
}

export default StickyHeader
