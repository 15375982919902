export class GlobalVariables {
	constructor() {
		let obj = {}
		let obj_val = {
			el: {
				// Main Elements
				body: {},
				site_wrapper: {},
				site_header: {},
				site_banner: {},

				// site_header: $('.site-header.blankslate-header'),
				// sticky_header: $('.blankslate-header.sticky-header'),

				// main_wrapper: $('#wrapper.content-wrapper'),
				// main_content: $('#main.main-area'),

				// primary_content: $('#primary.primary-content'),
				// secondary_content: $('#secondary.secondary-content'),

				// site_footer: $('#footer.site-footer'),

				// sidebar_header: $('#site-header-widgets'),
				// sidebar_content: $('#site-sidebar-widgets'),
				// sidebar_footer: $('#site-footer-widgets'),

				// back_to_top: $('.blankslate-back-to-top'),
			},
			sizes: {
				// Device Specs
				device_height: 0,
				device_width: 0,

				// Header Heights
				header_height: 0,
				header_o_height: 0,

				// // Footer Heights
				footer_height: 0,
				footer_o_height: 0,
			},
		}

		if (window.GloVars !== undefined) {
			obj_val = window.GloVars.configs
		}

		this.vars = Object.defineProperty(obj, 'configs', {
			value: obj_val,
			writable: true,
			enumerable: true,
			configurable: true,
		})
	}

	setValues(option) {
		const { el, sizes } = this.getVars()
		let temp = {
			value: this.getVars(),
			writable: true,
		}

		switch (option) {
			case 'init':
				temp.value.el.body = document.getElementsByTagName('body')
				temp.value.el.site_wrapper =
					document.getElementById('site-wrapper')
				temp.value.el.site_header = document.querySelector('#masthead')
				temp.value.el.site_banner =
					document.querySelector('#site-banner')

				temp.value.sizes.device_height = window.innerHeight
				temp.value.sizes.device_width = window.innerWidth
				temp.value.sizes.header_height = el.site_header.clientHeight // includes padding
				temp.value.sizes.header_o_height = el.site_header.offsetHeight // includes padding, scrollBar and borders

			case 'device_height':
				temp.value.sizes.device_height = window.innerHeight
			case 'device_width':
				temp.value.sizes.device_width = window.innerWidth
			case 'body_tag':
				temp.value.el.body = document.getElementsByTagName('body')
				break
			case 'site_wrapper':
				temp.value.el.site_wrapper =
					document.getElementById('site-wrapper')
				break
			case 'header':
				temp.value.el.site_header = document.querySelector('#masthead')

				temp.value.sizes.header_height = el.site_header.clientHeight // includes padding
				temp.value.sizes.header_o_height = el.site_header.offsetHeight // includes padding, scrollBar and borders
				break
		}

		Object.assign(this.vars, temp.value)

		// this.vars.configs.el = el
		// this.vars.configs.sizes = sizes

		console.log(this.vars)

		window.GloVars = Object.defineProperty({}, 'configs', {
			value: this.vars.configs,
			writable: true,
			enumerable: true,
			configurable: true,
		})

		return
	}

	init() {
		this.setValues('init')
		return this.getVars()
	}

	reload(option) {
		if (Array.isArray(option)) {
			for (var i = 0; i < option.length; i++) {
				this.setValues(option[i])
			}
		}

		if (typeof option === 'string' || option instanceof String) {
			this.setValues(option)
		}

		return this.getVars()
	}

	getVars() {
		return this.vars.configs
	}

	setVars() {
		console.log(this.body)

		this.vars = {
			el: {
				// Main Elements
				body: this.body,
				site_wrapper: this.site_wrapper,
				site_header: this.site_header,

				// site_header: $('.site-header.blankslate-header'),
				// sticky_header: $('.blankslate-header.sticky-header'),

				// main_wrapper: $('#wrapper.content-wrapper'),
				// main_content: $('#main.main-area'),

				// primary_content: $('#primary.primary-content'),
				// secondary_content: $('#secondary.secondary-content'),

				// site_footer: $('#footer.site-footer'),

				// sidebar_header: $('#site-header-widgets'),
				// sidebar_content: $('#site-sidebar-widgets'),
				// sidebar_footer: $('#site-footer-widgets'),

				// back_to_top: $('.blankslate-back-to-top'),
			},
			sizes: {
				// Device Specs
				device_height: window.innerHeight,
				device_width: window.innerWidth,

				// Header Heights
				header_height: this.header_height,
				header_o_height: this.header_o_height,

				// // Footer Heights
				// footer_height: 0,
				// footer_o_height: 0,

				// // WP Admin Bar Heights
				// wp_admin_bar_height: 0,
				// wp_admin_bar_o_height: 0,
			},
		}

		// window.GloVars = this.vars
		// // Header Heights
		// GloVars.sizes.header_height = GloVars.el.site_header.height()
		// GloVars.sizes.header_o_height = GloVars.el.site_header.outerHeight()

		// // Footer Heights
		// GloVars.sizes.footer_height = GloVars.el.site_footer.height()
		// GloVars.sizes.footer_o_height = GloVars.el.site_footer.outerHeight()

		// // WP Admin Bar Heights
		// GloVars.sizes.wp_admin_bar_height = GloVars.el.wp_admin_bar.height()

		// GloVars.sizes.wp_admin_bar_o_height =
		// 	GloVars.el.wp_admin_bar.outerHeight()
	}
}
export default GlobalVariables
