import React, { useEffect, useContext } from 'react'

import {
	StateContext,
	DispatchContext,
	SiteWrapper,
	Section,
} from '../../containers'

// Interfaces
import {
	UseDataFetchingRepositories,
	WithDataFetchingRepositories,
} from '../../components'

function Portfolios(props) {
	const appState = useContext(StateContext)
	const appDispatch = useContext(DispatchContext)

	useEffect(() => {
		appDispatch({ type: 'setPageTitle', value: 'Portfolios' })
	}, [])

	return (
		<SiteWrapper
			state={appState}
			dispatch={appDispatch}
			headerGutter={true}
			headerWide={true}
		>
			<Section id="site-banner" class="site-banner" wide={true}>
				<UseDataFetchingRepositories />
				<WithDataFetchingRepositories />
			</Section>
		</SiteWrapper>
	)
}

export default Portfolios
