import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

import React, {
	useState,
	useReducer,
	useEffect,
	forwardRef,
	createRef,
	Suspense,
} from 'react'

import { NavLink } from 'react-router-dom'

import ReactDOM from 'react-dom'

// Import React Transition Group
// import { Transition } from 'react-transition-group'

// Import Immer
import { useImmerReducer } from 'use-immer'

import { Init, Helpers, GlobalVariables, LoaderScreen } from './components'

import Main from './routes/Main'

function App() {
	const currentRef = createRef('main')
	const glovars = new GlobalVariables()
	const init = new Init()

	const initialState = {
		// Get the GlobalVariables values
		glovars: glovars.getVars(),
		pageTitle: '',
		LoaderScreen: {
			timeout: 2000,
			resetTimeoutInterval: 30,
			isLoading: true,
			debug: false,
			loader: {
				type: 'Bars',
				color: '#fff',
				backgroundColor: '#b4d93c',
				height: 120,
				width: 120,
			},
		},
		header: {
			sticky: true,
			gutter: false,
			wide: true,
		},
		menus: {},
		isLightboxOpen: false,
	}

	const stateReducer = (draft, action) => {
		switch (action.type) {
			case 'initGlovars':
				// Initialize the GlobalVariables class
				draft.glovars = glovars.init()
				return draft
			case 'reloadGlovars':
				// Reload and get the new GlobalVariables values
				draft.glovars = glovars.reload(action.option)
				return
			case 'showLoaderScreen':
				draft.LoaderScreen.isLoading = true
				return
			case 'hideLoaderScreen':
				draft.LoaderScreen.isLoading = false
				return
			case 'setPageTitle':
				draft.pageTitle = action.value
				init.setPageTitle(draft.pageTitle)
				return
			case 'getHeaderNav':
				return draft.menus
			case 'openLightbox':
				draft.isLightboxOpen = true
				return
			case 'closeLightbox':
				draft.isLightboxOpen = false
				return
		}
	}

	const [state, dispatch] = useImmerReducer(stateReducer, initialState)

	// Executes eventHanders and state dispatch
	init.exec(state, dispatch)

	return (
		<>
			<React.StrictMode>
				{/* {state.LoaderScreen.isLoading && <LoaderScreen />} */}
				<LoaderScreen
					config={{
						isLoading: state.LoaderScreen.isLoading,
						timeout: state.LoaderScreen.timeout,
						resetTimeoutInterval:
							state.LoaderScreen.resetTimeoutInterval,
						loader: {
							type: state.LoaderScreen.loader.type,
							height: state.LoaderScreen.loader.height,
							width: state.LoaderScreen.loader.width,
							color: state.LoaderScreen.loader.color,
							backgroundColor:
								state.LoaderScreen.loader.backgroundColor,
						},
					}}
				/>
				<Main
					refx={currentRef}
					state={state}
					dispatch={dispatch}
				></Main>
			</React.StrictMode>
		</>
	)
}

ReactDOM.render(<App />, document.querySelector('#app'))

if (module.hot) {
	module.hot.accept()
}

/**
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 *
 * reportWebVitals( console.log() )
 */
